<div>
  <h1>User Management</h1>
</div>

<!--Number of logins-->
<!-- <ejs-chart
      id="loginStatsGraph"
      [primaryXAxis]="XAxis"
      [primaryYAxis]="YAxis"
      [title]="loginGraphTitle"
      [titleStyle]="graphTitleStyle"
      [subTitle]="graphSubTitle"
      [subTitleStyle]="graphSubTitleStyle"
    >
      <e-series-collection>
        <e-series
          [dataSource]="loginStats"
          type="Line"
          xName="date"
          yName="logins"
          fill="#17b0f5"
        ></e-series>
      </e-series-collection>
    </ejs-chart> --><!-- Add User Dialog-->
<ejs-dialog
  id="addUserDialog"
  #ejDialogAddUser
  [showCloseIcon]="true"
  header="Add User"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddUserDialogCancelButtonClick()"
>
  <app-add-user></app-add-user>
</ejs-dialog>
<div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 20px">
  <!--Failed Login Grid-->
  <div class="e-card" style="order: 1; flex: 33; min-width: 500px">
    <div class="e-card-content">
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 20px;
          justify-content: space-between;
        "
      >
        <div
          style="
            order: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          "
        >
          <h2 style="order: 1">Failed Logins</h2>
        </div>

        <div
          style="
            order: 2;
            display: inline-block;
            display: flex;
            flex-direction: row;
          "
        >
          <p style="order: 1">Count</p>
          <span
            class="e-badge e-badge-info"
            style="order: 2; height: 17px; font-size: 12px"
            >{{ failedLogins.length }}</span
          >
        </div>
      </div>
      <ejs-grid
        id="failedLoginsGrid"
        #failedLoginsGrid
        (rowSelected)="onUserGridRowSelected($event.data)"
        (actionBegin)="onUserGridActionBegin($event)"
        [dataSource]="failedLogins"
        height="200px"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        allowResizing="false"
        searchSettings=""
        allowSearch="true"
        [searchSettings]="searchOptions"
        [allowTextWrap]="true"
        [toolbar]="toolbarUserActivityGrid"
        [enableHover]="enableRowHover"
      >
        <e-columns>
          <e-column
            field="date"
            headerText="Date"
            textAlign="Center"
            width="130"
            type="date"
            [format]="dateFormatOption"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="user_id"
            headerText="User ID"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="user_name"
            headerText="Username"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="type"
            headerText="Type"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="description"
            headerText="Description"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>

  <!--Blocked Users Grid-->
  <div class="e-card" style="order: 2; flex: 33; min-width: 500px">
    <div class="e-card-content">
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 20px;
          justify-content: space-between;
        "
      >
        <div
          style="
            order: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          "
        >
          <h2 style="order: 1">Blocked Users List</h2>
        </div>

        <div
          style="
            order: 2;
            display: inline-block;
            display: flex;
            flex-direction: row;
          "
        >
          <p style="order: 1">Count</p>
          <span
            class="e-badge e-badge-info"
            style="order: 2; height: 17px; font-size: 12px"
            >{{ blockedUsers.length }}</span
          >
        </div>
      </div>
      <ejs-grid
        id="blockedUsersGrid"
        #blockedUsersGrid
        (rowSelected)="onUserGridRowSelected($event.data)"
        (actionBegin)="onUserGridActionBegin($event)"
        [dataSource]="blockedUsers"
        height="200px"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        allowResizing="false"
        searchSettings=""
        allowSearch="true"
        [searchSettings]="searchOptions"
        [allowTextWrap]="true"
        [toolbar]="toolbarUserActivityGrid"
      >
        <e-columns>
          <e-column
            field="name"
            headerText="Company Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="given_name"
            headerText="First Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="family_name"
            headerText="Last Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="email"
            headerText="Email"
            textAlign="Center"
            width="180"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>

  <!--Active Users Grid-->
  <div class="e-card" style="order: 3; flex: 33; min-width: 500px">
    <div class="e-card-content">
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 20px;
          justify-content: space-between;
        "
      >
        <div
          style="
            order: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          "
        >
          <h2 style="order: 1">Active Users List</h2>
        </div>
        <div
          style="
            order: 2;
            display: inline-block;
            display: flex;
            flex-direction: row;
          "
        >
          <p style="order: 1">Count</p>
          <span
            class="e-badge e-badge-info"
            style="order: 2; height: 17px; font-size: 12px"
            >{{ activeUsers.length }}</span
          >
        </div>
      </div>
      <ejs-grid
        id="ActiveUsersGrid"
        #ActiveUsersGrid
        (rowSelected)="onUserGridRowSelected($event.data)"
        (actionBegin)="onUserGridActionBegin($event)"
        [dataSource]="activeUsers"
        height="200px"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        allowResizing="false"
        searchSettings=""
        allowSearch="true"
        [searchSettings]="searchOptions"
        [allowTextWrap]="true"
        [toolbar]="toolbarUserActivityGrid"
        [enableHover]="enableRowHover"
      >
        <e-columns>
          <e-column
            field="name"
            headerText="Company Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="given_name"
            headerText="First Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="family_name"
            headerText="Last Name"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="email"
            headerText="Email"
            textAlign="Center"
            width="180"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>

<!-- User Gird-->
<div style="height: 800px; width: 100%">
  <ejs-grid
    id="userGrid"
    #userGrid
    (rowSelected)="onUserGridRowSelected($event.data)"
    (actionBegin)="onUserGridActionBegin($event)"
    [dataSource]="userList"
    height="100%"
    width="100%"
    enableStickyHeader="true"
    [allowResizing]="true"
    gridLines="Default"
    [allowSorting]="true"
    [allowFiltering]="true"
    [filterSettings]="filterOption"
    [loadingIndicator]="loadingIndicator"
    allowResizing="true"
    allowSearch="true"
    [searchSettings]="searchOptions"
    [allowTextWrap]="true"
    [enableHover]="enableRowHover"
  >
    <ng-template #toolbarTemplate let-data>
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
        "
      >
        <div
          style="
            order: 1;
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            flex-direction: row;
          "
        >
          <div style="order: 1; padding-bottom: 5px">
            <button
              ejs-button
              style="padding-left: 10px; padding-right: 10px"
              cssClass="e-success"
              (click)="onClickAddButton()"
            >
              Add User
            </button>
          </div>
          <div
            style="
              order: 4;
              display: inline-block;
              display: flex;
              flex-direction: row;
            "
          >
            <p style="order: 1">Users</p>
            <span
              class="e-badge e-badge-info"
              style="order: 2; height: 17px; font-size: 12px"
              >{{ userList.length }}</span
            >
          </div>
        </div>

        <div style="order: 2; padding-bottom: 5px; padding-right: 5px">
          <ejs-textbox
            id="textbox-search"
            #textBoxSearch
            (keydown.enter)="onClickSearchButton()"
            [autofocus]="true"
            placeholder="Search For User"
            (keyup)="onChangeTextboxSearch($event)"
            style="float: left; padding-right: 10px; width: 250px"
            [value]="searchString"
          ></ejs-textbox>
          <button
            ejs-button
            id="search"
            cssClass="e-info"
            style="width: 75px"
            (click)="onClickSearchButton()"
          >
            Search
          </button>
          <button
            ejs-button
            id="clear-search"
            cssClass="e-warning"
            style="width: 75px; margin-left: 5px"
            (click)="onClickClearButton()"
            *ngIf="showClearSearchButton"
          >
            Clear
          </button>
        </div>
      </div>
    </ng-template>
    <e-columns>
      <e-column
        field="app_metadata.accountNumber"
        headerText="Account #"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="app_metadata.commissionCode"
        headerText="Commission Code"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="name"
        headerText="Company Name"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="username"
        headerText="User Name"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="given_name"
        headerText="First Name"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="family_name"
        headerText="Last Name"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="email"
        headerText="Email"
        textAlign="Center"
        width="180"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="last_login"
        headerText="Last Login"
        textAlign="Center"
        width="130"
        type="date"
        [format]="dateFormatOption"
        clipMode="EllipsisWithTooltip"
        [allowFiltering]="false"
      ></e-column>
      <e-column
        field="enable"
        headerText="Enabled"
        textAlign="Center"
        width="100"
        [allowFiltering]="false"
        ><ng-template #template let-data>
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            (change)="
              onChangeUserStatusSwitchClick($event, userStatusSwitch, data)
            "
            [checked]="!data.blocked"
          ></ejs-switch> </ng-template
      ></e-column>
      <e-column headerText="Action" textAlign="Center" width="130"
        ><ng-template #template let-data>
          <ejs-menu
            *ngIf="data.email && !data.email.includes('CascadeDesigns.com')"
            target="#target"
            [items]="menuItems"
            showItemOnClick="true"
            (select)="onContextMenuSelect($event)"
          ></ejs-menu>
          <ejs-menu
            *ngIf="data.email && data.email.includes('CascadeDesigns.com')"
            target="#target"
            [items]="menuItemsFiltered"
            showItemOnClick="true"
            (select)="onFilteredMenuSelect($event)"
          ></ejs-menu> </ng-template
      ></e-column>
    </e-columns>
  </ejs-grid>
</div>
<div>
  <!-- Edit User Dialog-->
  <ejs-dialog
    id="editUserDialog"
    #ejDialogEditUser
    [showCloseIcon]="true"
    [height]=""
    [target]=""
    width="435px"
    isModal="true"
    [closeOnEscape]="false"
    [visible]="false"
    (close)="onEditUserDialogCancelButtonClick()"
  >
    <form [formGroup]="editUserForm">
      <ng-template #header>
        <div class="e-icon-settings dlg-template">Edit User</div>
      </ng-template>

      <ng-template #content>
        <div class="dialogContent">
          <div>
            <label for="edit-first-name" class="dialogText">First Name: </label>
            <input
              id="edit-first-name"
              class="e-input"
              type="text"
              placeholder="Enter First Name"
              formControlName="editFirstName"
            />
            <div
              *ngIf="
                editFirstName?.invalid &&
                (editFirstName?.dirty || editFirstName?.touched)
              "
              class="form-error"
            >
              <div *ngIf="editFirstName?.errors?.['required']">
                * firstName is required.
              </div>
              <div *ngIf="editFirstName?.errors?.['minlength']">
                * firstName must be at least 2 characters long.
              </div>
            </div>
          </div>
          <br /><br />
          <div>
            <label for="edit-last-name" class="dialogText">Last Name: </label>
            <input
              id="edit-last-name"
              class="e-input"
              type="text"
              placeholder="Enter Last Name"
              formControlName="editLastName"
            />
            <div
              *ngIf="
                editLastName?.invalid &&
                (editLastName?.dirty || editLastName?.touched)
              "
              class="form-error"
            >
              <div *ngIf="editLastName?.errors?.['required']">
                * lastName is required.
              </div>
              <div *ngIf="editLastName?.errors?.['minlength']">
                * lastName must be at least 2 characters long.
              </div>
            </div>
          </div>
          <br /><br />
          <div>
            <label for="edit-user-email" class="dialogText">Email: </label>
            <input
              id="edit-user-email"
              class="e-input"
              type="email"
              placeholder="Enter Email"
              formControlName="editEmail"
            />
            <div
              *ngIf="
                editEmail?.invalid && (editEmail?.dirty || editEmail?.touched)
              "
              class="form-error"
            >
              <div *ngIf="editEmail?.errors?.['required']">
                * email is required.
              </div>
              <div *ngIf="editEmail?.errors?.['email']">
                * Please Enter a Valid email.
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <button
          id="editUserCancelButton"
          class="e-control e-btn e-warning sendButton"
          data-ripple="true"
          (click)="onEditUserDialogCancelButtonClick()"
        >
          Cancel
        </button>
        <button
          id="editUserAddButton"
          class="e-control e-btn e-success sendButton"
          data-ripple="true"
          [disabled]="!editUserForm.valid"
          (click)="onSaveEditUserDataButtonClick()"
        >
          Save
        </button>
        <br /><br />
      </ng-template>
    </form>
  </ejs-dialog>

  <!-- Assign/Edit Profile Dialog-->
  <ejs-dialog
    id="assigneProfileDialog"
    #ejDialogAssignRole
    [showCloseIcon]="true"
    [height]=""
    [target]=""
    width="435px"
    isModal="true"
    [closeOnEscape]="false"
    [visible]="false"
    (close)="onAssignProfileDialogCancelButtonClick()"
  >
    <form [formGroup]="userRoleFormGroup">
      <ng-template #header>
        <div class="e-icon-settings dlg-template">{{ dialogTitle }}</div>
      </ng-template>

      <ng-template #content>
        <div class="dialogContent">
          <label for="user-role" class="dialogText">Roles: </label>
          <ejs-dropdownlist
            *ngIf="!isEditableRole"
            id="user-role"
            #dropdownlistUserRole
            [dataSource]="userRoles"
            [fields]="dropDownUserRoleFields"
            placeholder="Select Role"
            formControlName="roles"
            (select)="onRolesDropDownSelect($event)"
          ></ejs-dropdownlist>
          <ejs-dropdownlist
            *ngIf="isEditableRole"
            id="user-role"
            #dropdownlistUserRole
            [dataSource]="userRoles"
            [fields]="dropDownUserRoleFields"
            placeholder="Select Role"
            formControlName="roles"
            (select)="onRolesDropDownSelect($event)"
            readonly="true"
          ></ejs-dropdownlist>
          <div
            *ngIf="roles?.invalid && (roles?.dirty || roles?.touched)"
            class="form-error"
          >
            <div *ngIf="roles?.errors?.['required']">
              * please select a role from the dropdown.
            </div>
          </div>
          <br />
          <div *ngIf="isRoleSelected">
            <div *ngIf="isDealerRole">
              <label for="account-number" class="dialogText"
                >Account Number:
              </label>
              <input
                id="account-number"
                class="e-input"
                type="number"
                placeholder="Enter Account Number"
                formControlName="accountNumber"
              />
              <div
                *ngIf="
                  accountNumber?.invalid &&
                  (accountNumber?.dirty || accountNumber?.touched)
                "
                class="form-error"
              >
                <div *ngIf="accountNumber?.errors?.['required']">
                  * accountNumber is required.
                </div>
                <div *ngIf="accountNumber?.errors?.['maxlength']">
                  * Account number must be at 6 characters long.
                </div>
              </div>
              <br /><br />
            </div>

            <div *ngIf="!isDealerRole">
              <label for="commission-code" class="dialogText"
                >Commission Code:
              </label>
              <input
                id="commission-code"
                class="e-input"
                type="number"
                placeholder="Enter Commission Code"
                formControlName="commissionCode"
              />
              <div
                *ngIf="
                  commissionCode?.invalid &&
                  (commissionCode?.dirty || commissionCode?.touched)
                "
                class="form-error"
              >
                <div *ngIf="commissionCode?.errors?.['required']">
                  * commissionCode is required.
                </div>
              </div>
              <br /><br />
            </div>

            <label for="company-code" class="dialogText">Company Code: </label>
            <ejs-dropdownlist
              id="company-code"
              class="e-input"
              placeholder="Select Company Code"
              formControlName="companyCode"
              [dataSource]="companycode"
              [fields]="dropDownCompanyCodeFields"
            ></ejs-dropdownlist>

            <div
              *ngIf="
                companyCode?.invalid &&
                (companyCode?.dirty || companyCode?.touched)
              "
              class="form-error"
            >
              <div *ngIf="companyCode?.errors?.['required']">
                * companyCode is required.
              </div>
            </div>
            <br /><br />

            <label for="branch-plant" class="dialogText">Branch Plant: </label>
            <ejs-dropdownlist
              id="branch-plant"
              class="e-input"
              placeholder="Select Branch Plan"
              formControlName="branchPlant"
              [dataSource]="branchplant"
              [fields]="dropDownBranchPlantFields"
            ></ejs-dropdownlist>
            <div
              *ngIf="
                branchPlant?.invalid &&
                (branchPlant?.dirty || branchPlant?.touched)
              "
              class="form-error"
            >
              <div *ngIf="branchPlant?.errors?.['required']">
                * branchPlant is required.
              </div>
            </div>
            <br /><br />

            <label for="sbu-code" class="dialogText">SBU Code: </label>
            <ejs-dropdownlist
              id="sbu-code"
              class="e-input"
              placeholder="Select SBU Code"
              formControlName="sbuCode"
              [dataSource]="sbu"
              [fields]="dropDownSbuFields"
            ></ejs-dropdownlist>
            <div
              *ngIf="sbuCode?.invalid && (sbuCode?.dirty || sbuCode?.touched)"
              class="form-error"
            >
              <div *ngIf="sbuCode?.errors?.['required']">
                * sbuCode is required.
              </div>
            </div>
            <br /><br />
          </div>
        </div>
      </ng-template>

      <ng-template #footerTemplate>
        <button
          id="assignUserProfileCancelButton"
          class="e-control e-btn e-warning sendButton"
          data-ripple="true"
          (click)="onAssignProfileDialogCancelButtonClick()"
        >
          Cancel
        </button>
        <button
          id="assigneUserProfileSaveButton"
          class="e-control e-btn e-success sendButton"
          data-ripple="true"
          [disabled]="!userRoleFormGroup.valid"
          (click)="onSubmitAssignUserProfile()"
        >
          {{ assignAndEditButtonText }}
        </button>
      </ng-template>
    </form>
  </ejs-dialog>

  <!-- Password Reset Dialog-->
  <ejs-dialog
    id="resetPasswordDialog"
    #ejDialogPasswordReset
    [showCloseIcon]="true"
    isModal="true"
    closeOnEscape="false"
    [height]=""
    [target]=""
    width="435px"
    [visible]="false"
    ><form [formGroup]="passwordResetFormGroup">
      <ng-template #header>
        <div title="passwordReset" class="e-icon-settings dlg-template">
          Password Reset
        </div>
      </ng-template>

      <ng-template #content>
        <div class="dialogContent">
          <label for="new-password" class="dialogText">New Password: </label>
          <input
            id="new-password"
            class="e-input"
            type="password"
            placeholder="New Password"
            formControlName="newPassword"
          />
          <div
            *ngIf="
              newPassword?.invalid &&
              (newPassword?.dirty || newPassword?.touched)
            "
            class="form-error"
          >
            <div *ngIf="newPassword?.errors?.['required']">
              * new Password is required.
            </div>
            <div *ngIf="newPassword?.errors?.['minlength']">
              * new Password must be at least 8 characters long.
            </div>
            <div *ngIf="newPassword?.errors?.['invalidPassword']">
              * Password should be alphanumeric, contain a special character,
              and have at least one uppercase letter.
            </div>
          </div>
          <br /><br />
          <label for="confirm-password" class="dialogText"
            >Confirm Password:
          </label>
          <input
            id="confirm-password"
            class="e-input"
            type="password"
            placeholder="Confirm Password"
            formControlName="confirmPassword"
          />
          <div
            *ngIf="
              confirmPassword?.invalid &&
              (confirmPassword?.dirty || confirmPassword?.touched)
            "
            class="form-error"
          >
            <div *ngIf="confirmPassword?.errors?.['required']">
              * confirm Password is required.
            </div>
            <div *ngIf="confirmPassword?.errors?.['minlength']">
              * confirm Password must be at least 8 characters long.
            </div>
            <div *ngIf="!passwordResetFormGroup.valid">
              * new Passsword and Confirm Password didn't match.
            </div>
          </div>
          <br /><br />
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <button
          id="resetPasswordrCancelButton"
          class="e-control e-btn e-warning sendButton"
          data-ripple="true"
          (click)="onPasswordResetDialogCancelButtonClick()"
        >
          Cancel
        </button>
        <button
          id="resetPasswordButton"
          class="e-control e-btn e-success sendButton"
          data-ripple="true"
          [disabled]="!passwordResetFormGroup.valid"
          (click)="onSubmitResetPassword()"
        >
          Reset Password
        </button>
      </ng-template>
    </form>
  </ejs-dialog>

  <!-- User status Warning Dialog-->
  <ejs-dialog
    id="dialog-user-status"
    #ejDialogUserStatus
    [showCloseIcon]="false"
    isModal="true"
    [closeOnEscape]="false"
    [height]=""
    [target]=""
    width="300px"
    [visible]="false"
  >
    <ng-template #header>
      <div title="enbaleDisableUser" class="e-icon-settings dlg-template">
        {{ dialogUserStatusWarning }} User
      </div>
    </ng-template>
    <ng-template #content
      >Are you sure you want to {{ dialogUserStatusWarning }} the
      User?</ng-template
    >
    <ng-template #footerTemplate>
      <button
        id="userStatusNoButton"
        class="e-control e-btn e-danger sendButton"
        data-ripple="true"
        (click)="onUserStatusDialogNoButtonClick()"
      >
        No
      </button>
      <button
        id="userStatusYesButton"
        class="e-control e-btn e-success sendButton"
        data-ripple="true"
        (click)="onUserStatusDialogYesButtonClick()"
      >
        Yes
      </button>
    </ng-template>
  </ejs-dialog>

  <!-- Activity Dialog with Grid -->
  <ejs-dialog
    id="ejDialogLoginActivity"
    #ejDialogLoginActivity
    [showCloseIcon]="true"
    isModal="true"
    [closeOnEscape]="false"
    [height]=""
    [target]=""
    [visible]="false"
    [target]=""
    width="800px"
    height="500px"
    ><ng-template #header>
      <div title="activityDialogHeader" class="e-icon-settings dlg-template">
        Activity Logs
      </div>
    </ng-template>
    <ejs-grid
      [dataSource]="userActivityLogs"
      #loginActivityGrid
      width="100%"
      height="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [toolbar]="toolbarUserActivityGrid"
      [allowExcelExport]="true"
      (toolbarClick)="onToolbarClickLoginActivityGrid($event)"
      (excelExportComplete)="onLoginActivityGridExcelExportComplete()"
      ><e-columns>
        <e-column
          field="date"
          headerText="Date"
          textAlign="center"
          width="60"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="type"
          headerText="Type"
          textAlign="center"
          width="50"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="ip"
          headerText="IP Address"
          textAlign="center"
          width="50"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="location_info.country_name"
          headerText="Country Name"
          textAlign="center"
          width="40"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="location_info.city_name"
          headerText="City Name"
          textAlign="center"
          width="40"
          clipMode="EllipsisWithTooltip"
        ></e-column> </e-columns
    ></ejs-grid>
    <ng-template #footerTemplate>
      <button
        id="loginActivityDialogCancelButton"
        class="e-control e-btn e-danger sendButton"
        data-ripple="true"
        (click)="onLoginActivityDialogCancelButtonClick()"
      >
        Close
      </button>
    </ng-template>
  </ejs-dialog>
</div>
<br />
<ejs-dialog
  id="dialogDeleteUser"
  #dialogDeleteUser
  [showCloseIcon]="true"
  [isModal]="true"
  [visible]="false"
  width="30vw"
  [enableResize]="true"
  (close)="onCloseDialogDeleteUser()"
  [header]="deleteUserDialogTitle"
  content="Are you sure you want to delete user?"
>
  <ng-template #footerTemplate>
    <button
      ejs-button
      cssClass="e-success"
      (click)="onClickYesButtonOfUserConfirmationDeleteDialog()"
    >
      Yes
    </button>
    <button
      ejs-button
      cssClass="e-danger"
      (click)="onClickNoButtonOfUserConfirmationDeleteDialog()"
    >
      No
    </button>
  </ng-template>
</ejs-dialog>
