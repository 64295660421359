// sidebar.component.ts
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() selectedItem = new EventEmitter<string>();

  public data: Object = [
    { text: 'Salesforce option 1', id: '01' },
    { text: 'Salesforce option 2', id: '02' },
    { text: 'Salesforce option 3', id: '03' },
    { text: 'Salesforce option 4', id: '04' },
    { text: 'Salesforce option 5', id: '05' },
    { text: 'Salesforce option 6', id: '06' },
  ];

  public onItemSelected(event: any): void {
    const selectedItem = event.text;
    this.selectedItem.emit(selectedItem);
  }
}
