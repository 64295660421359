<div class="e-card">
  <div class="e-card-content">
    <nav
      style="
        display: flex;
        flex-direction: row;
        height: 75px;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
      "
    >
      <div style="order: 1">
        <a href="/dashboard">
          <img
            src="assets/img/CDI_LOGO.svg"
            height="45px"
            alt="Cascade Designs Inc."
        /></a>
      </div>
      <div style="order: 2; flex: 1">
        <div *ngIf="_router.url !== '/dashboard'">
          <ejs-menu [items]="menuItems"></ejs-menu>
        </div>
      </div>
      <div style="order: 3">
        <ejs-tooltip
          id="tooltip"
          content="Notifications"
          position="BottomCenter"
        >
          <img
            src="../../../assets/img/notification-bell-orange.png"
            height="35px"
            width="35px"
            alt=""
        /></ejs-tooltip>
        <ejs-tooltip
          id="tooltip"
          content="click here to visit help center"
          position="BottomCenter"
        >
          <a routerLink="/help-center">
            <img
              src="../../../assets/img/help-center.png"
              height="35px"
              width="35px"
              alt="Help Center"
          /></a>
        </ejs-tooltip>
      </div>
      <div style="order: 5">
        <!-- Profile Menu -->
        <button
          style="background-color: #303030 !important"
          *ngIf="userService.isAuthenticated"
          ejs-dropdownbutton
          [items]="profileMenu"
          cssClass="e-caret-hide"
          (select)="onLogoutButtonClick($event)"
        >
          <span
            class="e-avatar e-avatar-large e-avatar-circle"
            style="background-color: #2f96b4 !important"
            >{{ userService.userProfile.initials }}</span
          >
        </button>
      </div>
    </nav>
  </div>
</div>
