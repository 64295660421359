import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CdiAppMonitorService } from './cdi-app-monitor.service';
import { CacheService } from './cache.service';
import { DialogType } from '../shared/notification/model/dialog-type.enum';
import { NotificationMessage } from '../shared/notification/model/notification-message.model';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _cdiAppMonitor: CdiAppMonitorService;

  constructor(private injector: Injector, private _cache: CacheService) {
    this._cdiAppMonitor = this.injector.get(CdiAppMonitorService);
  }

  public handleError(error: Error | HttpErrorResponse): void {
    this._cdiAppMonitor.logException(error);
    const errorMessage: string = 'An error has occurred; Please try again.';
    let errorDetail: string = '';
    if (error instanceof HttpErrorResponse) {
      // server side error
      if (error.status != null && error.status != undefined) {
        if (error.status === 0) {
          errorDetail = `System Not Available`;
        } else {
          if (error.error && error.status === 400) {
            errorDetail = `${this.parseBadRequest(error)}`;
          } else if (error.error && error.status === 401) {
            errorDetail = '401. Authentication Failed. Please login.';
          } else if (error.error && error.status === 403) {
            errorDetail =
              '403. Authorization Failed. <br/> You do not have permissions to access resource.';
          } else if (error.error && error.status === 404) {
            errorDetail = `Status Code: 404; Not Found`;
          } else if (error.error && error.status === 500) {
            errorDetail = error.error;
          } else if (error.error && error.status === 512) {
            errorDetail = `Id: ${error.error.cdiEventId}<br/> ${error?.error?.message}`;
          } else if (error.error && error.status === 530) {
            console.log('530', error);
            errorDetail = error.error.message;
          }
        }
      }
    } else {
      // client side error
      errorDetail = error.message ? error.message : '';
      console?.error(error);
    }

    this._cache.showDialog.next(
      new NotificationMessage(DialogType.error, 'Error', errorDetail)
    );
  }

  private parseBadRequest(err: any): string {
    let errorMessage: string = 'Validation Error';
    console.log('parseBadRequest', err);
    try {
      if (err.error != null && err.error != undefined) {
        if (
          err?.error?.title?.includes('One or more validation errors occurred')
        ) {
          errorMessage = `One or more validation errors occurred.<br/>${this.parseValidationError(
            err.error.errors
          )}`;
        }
      }
    } catch (error) {
      return 'unavailable.';
    }
    return errorMessage;
  }
  private parseValidationError(data: any): string {
    let validationErrors: string = '';
    try {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          validationErrors = validationErrors + `${data[key]}<br/>`;
        }
      }
    } catch (error) {
      return 'Unable to Parse Validation Error';
    }
    return validationErrors;
  }
}
