<div>
  <h2>Portal Management</h2>
  <h3>Order Minimum</h3>
</div>
<!-- Clone Order Dialog-->
<ejs-dialog
  id="cloneOrderDialog"
  #ejDialogCloneOrder
  [showCloseIcon]="true"
  [height]=""
  [target]=""
  width="435px"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  (close)="onCloneOrderDialogCancelButtonClick()"
>
  <form [formGroup]="cloneOrderForm">
    <ng-template #header>
      <div class="e-icon-settings dlg-template">Clone</div>
    </ng-template>

    <ng-template #content>
      <div class="dialogContent">
        <ejs-dropdownlist
          id="ddlOrderYear"
          placeholder="Select Order Year"
          [dataSource]="orderYearList"
          [fields]="dropdownlistOrderYearSearchFields"
        ></ejs-dropdownlist>
        <div
          *ngIf="orderYear?.invalid && (orderYear?.dirty || orderYear?.touched)"
          class="form-error"
        >
          <div *ngIf="orderYear?.errors?.['required']">
            * Order Year is required.
          </div>
        </div>
      </div>
      <br /><br />
    </ng-template>
    <ng-template #footerTemplate>
      <button
        id="cloneOrderCancelButton"
        class="e-control e-btn e-warning sendButton"
        data-ripple="true"
        (click)="onCloneOrderDialogCancelButtonClick()"
      >
        Cancel
      </button>
      <button
        id="addOrderAddButton"
        class="e-control e-btn e-success sendButton"
        data-ripple="true"
        [disabled]="!addOrderForm.valid"
        (click)="onSaveCloneOrderDataButtonClick()"
      >
        Ok
      </button>
      <br /><br />
    </ng-template>
  </form>
</ejs-dialog>
<!-- User Gird-->
<!-- (rowSelected)="onOrderMinimumGridRowSelected($event.data)" -->
<div style="height: 800px; width: 100%">
  <ejs-grid
    id="orderMinimumGrid"
    #orderMinimumGrid
    (actionBegin)="onOrderMinimumGridActionBegin($event)"
    [dataSource]="orderMinimumList"
    [allowPaging]="false"
    [allowSorting]="true"
    allowTextWrap="true"
    [allowFiltering]="true"
    [filterSettings]="filterOption"
    [loadingIndicator]="loadingIndicator"
    width="100%"
    height="400px"
    [toolbar]="toolbarOrderMinimumGrid"
    [editSettings]="editSettings"
    [allowPdfExport]="true"
    [allowExcelExport]="true"
    [allowResizing]="true"
    (actionComplete)="onActionCompleteOrderMinimum($event)"
    [showColumnChooser]="true"
    [enableHover]="enableRowHover"
  >
    <e-columns>
      <e-column
        field="dateCreated"
        headerText="Date Created"
        textAlign="Center"
        width="130"
        type="date"
        [format]="dateFormatOption"
        clipMode="EllipsisWithTooltip"
        [allowFiltering]="false"
        clipMode="EllipsisWithTooltip"
        [visible]="false"
      ></e-column>
      <e-column
        field="companyCode"
        headerText="Company Code"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="currencyCode"
        headerText="Currency Code"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="countryCode"
        headerText="Country Code"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="orderMinimumAmount"
        headerText="Minimum Amount"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="handlingCharge"
        headerText="Handling Charge"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="orderYear"
        headerText="Order Year"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="isActive"
        headerText="Is Active"
        textAlign="Center"
        width="100"
        [allowFiltering]="false"
        ><ng-template #template let-data>
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="data.isActive"
          ></ejs-switch> </ng-template></e-column
      ><e-column
        field="dateModified"
        headerText="Date Modified"
        textAlign="Center"
        width="130"
        type="date"
        [format]="dateFormatOption"
        clipMode="EllipsisWithTooltip"
        [allowFiltering]="false"
        [visible]="false"
      ></e-column>
      <e-column
        field="createdOrModifiedBy"
        headerText="Created Or Modified By"
        textAlign="Center"
        width="130"
        clipMode="EllipsisWithTooltip"
        [visible]="false"
      ></e-column>
    </e-columns>
  </ejs-grid>
</div>
