import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './content/dashboard/dashboard.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { HelpCenterComponent } from './content/help-center/help-center.component';
import { UserManagementComponent } from './content/user-management/user-management.component';
import { OrderMinimumComponent } from './content/order-minimum/order-minimum.component';
import { EmailDeliveryLogComponent } from './content/email-delivery-log/email-delivery-log.component';
import { ReportConfigurationComponent } from './content/report-configuration/report-configuration.component';
import { ApiLogComponent } from './content/api-log/api-log.component';
import { SalesForceComponent } from './content/sales-force/sales-force.component';
import { AddUserComponent } from './content/add-user/add-user.component';
import { OrderModificationSettingsComponent } from './content/order-modification-settings/order-modification-settings.component';
import { SettingsComponent } from './content/settings/settings.component';
import { IPlannerComponent } from './content/iplanner/iplanner.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'help-center',
    component: HelpCenterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order-minimum',
    component: OrderMinimumComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'email-delivery-log',
    component: EmailDeliveryLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report-configuration',
    component: ReportConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'api-log',
    component: ApiLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order-modification-settings',
    component: OrderModificationSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales-force',
    component: SalesForceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'iplanner',
    component: IPlannerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-user',
    component: AddUserComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
