import { Component, ViewChild } from '@angular/core';
import {
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { EventLog } from './model/event-log.mdel';

@Component({
  selector: 'app-api-log',
  templateUrl: './api-log.component.html',
  styleUrls: ['./api-log.component.scss'],
})
export class ApiLogComponent {
  public apiLogList: EventLog[] = [];
  @ViewChild('apiLogGrid') public apiLogGrid?: GridComponent;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public toolbarEmailDeliveryLogGrid?: ToolbarItems[];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public editSettings?: EditSettingsModel;
  public toolbarApiLogGrid?: ToolbarItems[];
  public enableRowHover: boolean = false;

  constructor(private dataService: DataserviceService) {}

  ngOnInit(): void {
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
    };
    this.toolbarApiLogGrid = [
      'Add',
      'Edit',
      'Cancel',
      'ColumnChooser',
      'Search',
    ];
    this.getApiLog();
  }

  public getApiLog() {
    this.dataService.getAll<EventLog>('ApiLog/GetApiLog').subscribe((data) => {
      this.apiLogList = data;
    });
  }
}
