import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { User } from '../../shared/model/user.model';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FieldMatchValidatior } from '../../shared/custom-validators/field-match.validator';
import {
  ExcelExportProperties,
  FilterSettingsModel,
  Grid,
  GridComponent,
  PdfExportProperties,
  SearchSettingsModel,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  MenuEventArgs,
  MenuItemModel,
  ClickEventArgs,
  SelectEventArgs,
  TabComponent,
} from '@syncfusion/ej2-angular-navigations';
import { AppMetadata } from '../../shared/model/app-metadata.model';
import { Role } from '../../shared/model/role.model';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Sbu } from '../../shared/model/sbu.model';
import { CompanyCode } from '../../shared/model/company-code.model';
import { BranchPlant } from '../../shared/model/branch-plant.model';
import { UserRole } from '../../shared/model/user-role.model';
import { Observable } from 'rxjs';
import { PasswordReset } from '../../shared/model/password-reset.model';
import { EditUser } from '../../shared/model/edit-user.model';
import { UserActivityLog } from '../../shared/model/user-activity-log.model';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { UserService } from 'src/app/services/user.service';
import { LoginStats } from '../../shared/model/login-stats.model';
import { FailedLogin } from './model/failed-login.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  public userList: User[] = [];
  @ViewChild('userGrid') public userGrid?: GridComponent;
  @ViewChild('failedLoginsGrid') public failedLoginsGrid?: GridComponent;
  @ViewChild('blockedUsersGrid') public blockedUsersGrid?: GridComponent;
  @ViewChild('activeUsersGrid') public activeUsersGrid?: GridComponent;
  @ViewChild('loginActivityGrid') public loginActivityGrid?: GridComponent;
  @ViewChild('ejDialogAddUser') ejDialogAddUser!: DialogComponent;
  @ViewChild('ejDialogEditUser') ejDialogEditUser!: DialogComponent;
  @ViewChild('ejDialogAssignRole') ejDialogAssignRole!: DialogComponent;
  @ViewChild('ejDialogPasswordReset') ejDialogPasswordReset!: DialogComponent;
  @ViewChild('dialogDeleteUser') dialogDeleteUser!: DialogComponent;
  @ViewChild('ejDialogUserStatus')
  ejDialogUserStatus!: DialogComponent;
  @ViewChild('ejDialogLoginActivity')
  ejDialogLoginActivity!: DialogComponent;
  @ViewChild('dropdownlistUserRole')
  dropdownlistUserRole!: DropDownListComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;
  @ViewChild('tab') tab?: TabComponent;

  public userSwitch: any;

  // Declared  Variables
  public user: User;
  public dialogTitle: string;
  public assignAndEditButtonText: string;
  public isDealerRole?: boolean;
  public isRoleSelected: boolean;
  public isEditableRole: boolean = false;
  public dialogUserStatusWarning: string;
  public userRoles: Role[];
  public loginStats: LoginStats[];
  public sbu: Sbu[];
  public dropDownSbuFields: Object = { text: 'name', value: 'id' };
  public companycode: CompanyCode[];
  public dropDownCompanyCodeFields: Object = { text: 'name', value: 'id' };
  public branchplant: BranchPlant[];
  public dropDownBranchPlantFields: Object = { text: 'name', value: 'id' };
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public dropDownUserRoleFields: Object = { text: 'name', value: 'id' };
  public isUserStatusDialogNoButtonClicked: boolean;
  public searchString: string = '';
  public userActivityLogs: UserActivityLog[];
  public toolbarUserActivityGrid?: ToolbarItems[];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public deleteUserDialogTitle: string = '';
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public toolbarOptions: ToolbarItems[] = [];
  public XAxis: object;
  public YAxis: object;
  public loginGraphTitle: string;
  public graphTitleStyle: object;
  public graphSubTitle: string;
  public graphSubTitleStyle: object;
  public failedLogins: FailedLogin[];
  public blockedUsers: User[];
  public activeUsers: User[];
  public enableRowHover: boolean = false;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService,
    private userService: UserService
  ) {
    this.isUserStatusDialogNoButtonClicked = false;
    this.showClearSearchButton = false;
    this.userRoles = [];
    this.loginStats = [];
    this.isRoleSelected = false;
    this.dialogUserStatusWarning = '';
    this.user = new User();
    this.sbu = [];
    this.branchplant = [];
    this.companycode = [];
    this.dialogTitle = '';
    this.assignAndEditButtonText = '';
    this.userActivityLogs = [];
    this.XAxis = { valueType: 'DateTime', title: '' };
    this.YAxis = { title: '' };
    this.loginGraphTitle = 'Number of Logins';
    this.graphTitleStyle = {
      size: '18px',
      color: 'White',
      textAlignment: 'Near',
      textOverflow: 'Wrap',
    };
    this.graphSubTitle = 'Within the past month';
    this.graphSubTitleStyle = {
      fontStyle: 'italic',
      fontWeight: 'regular',
      color: '#ffffff',
      size: '13px',
    };
    this.failedLogins = [];
    this.blockedUsers = [];
    this.activeUsers = [];
  }

  ngOnInit(): void {
    this.toolbarUserActivityGrid = ['Search'];
    this.getSbu();
    this.getRoles();
    this.getLoginStats();
    this.getBranchPlant();
    this.getCompanyCode();
    this.getFailedLogins();
    this.getBlockedUsers();
    this.getActiveUsers();
  }

  public menuItems: MenuItemModel[] = [
    {
      // iconCss: 'e-icons e-more-horizontal-1',
      text: 'Action',
      items: [
        {
          text: 'Edit User',
          id: 'editUser',
          iconCss: 'e-icons e-edit',
        },
        {
          text: 'Delete User',
          id: 'deleteUser',
          iconCss: 'e-icons e-trash',
        },
        {
          text: 'Edit Profile',
          id: 'editProfile',
          iconCss: 'e-icons e-people',
        },
        {
          text: 'Password Reset',
          id: 'passwordReset',
          iconCss: 'e-icons e-reset',
        },
        {
          text: 'Login Activity',
          id: 'loginActivity',
          iconCss: 'e-icons e-clock',
        },
      ],
    },
  ];
  public menuItemsFiltered: MenuItemModel[] = [
    {
      // iconCss: 'e-icons e-more-horizontal-1',
      text: 'Action',
      items: [
        {
          text: 'Delete User',
          id: 'deleteUser',
          iconCss: 'e-icons e-trash',
        },
        {
          text: 'Password Reset',
          id: 'passwordReset',
          iconCss: 'e-icons e-reset',
        },
        {
          text: 'Login Activity',
          id: 'loginActivity',
          iconCss: 'e-icons e-clock',
        },
      ],
    },
  ];
  onContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;

    // Check the ID of the selected menu item
    switch (selectedItem.id) {
      case 'editUser':
        this.onClickEditUserButton(event);
        break;
      case 'deleteUser':
        this.onClickDeleteUserButton(event);
        break;
      case 'editProfile':
        this.isEditableRole = true;
        this.onClickEditProfileButton(event);
        break;
      case 'passwordReset':
        this.onClickPasswordResetButton(event);
        break;
      case 'loginActivity':
        this.onClickViewActivityLogsButton(event);
        break;
      default:
        // Handle other menu items as needed
        break;
    }
  }
  onFilteredMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    switch (selectedItem.id) {
      case 'deleteUser':
        this.onClickDeleteUserButton(event);
        break;
      case 'passwordReset':
        this.onClickPasswordResetButton(event);
        break;
      case 'loginActivity':
        this.onClickViewActivityLogsButton(event);
        break;
      default:
        // Handle other menu items as needed
        break;
    }
  }
  // Gets for User forms
  get name() {
    return this.addUserForm.get('name');
  }
  get username() {
    return this.addUserForm.get('username');
  }
  get firstName() {
    return this.addUserForm.get('firstName');
  }
  get lastName() {
    return this.addUserForm.get('lastName');
  }
  get email() {
    return this.addUserForm.get('email');
  }
  get password() {
    return this.addUserForm.get('password');
  }
  get confirmPasswordOnAddUser() {
    return this.addUserForm.get('confirmPasswordOnAddUser');
  }
  get commissionCode() {
    return this.addUserForm.get('commissionCode');
  }
  get companyCode() {
    return this.addUserForm.get('companyCode');
  }
  get branchPlant() {
    return this.addUserForm.get('branchPlant');
  }
  get sbuCode() {
    return this.addUserForm.get('sbuCode');
  }
  get blocked() {
    return this.addUserForm.get('blocked');
  }
  get accountNumber() {
    return this.addUserForm.get('accountNumber');
  }
  get roles() {
    return this.addUserForm.get('roles');
  }
  // Gets for Password Reset forms
  get newPassword() {
    return this.passwordResetFormGroup.get('newPassword');
  }
  get confirmPassword() {
    return this.passwordResetFormGroup.get('confirmPassword');
  }

  // edit Getters
  get editUsername() {
    return this.editUserForm.get('editUsername');
  }
  get editFirstName() {
    return this.editUserForm.get('editFirstName');
  }
  get editLastName() {
    return this.editUserForm.get('editLastName');
  }
  get editEmail() {
    return this.editUserForm.get('editEmail');
  }
  private passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value as string;

      // Define a regular expression pattern for the password criteria
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;

      if (!passwordPattern.test(password)) {
        // Password does not meet the criteria, so return an error
        return { invalidPassword: true };
      }

      // Password is valid
      return null;
    };
  }
  // User Form Group
  public addUserForm = new FormGroup(
    {
      name: new FormControl([Validators.required, Validators.minLength(3)]),
      username: new FormControl([Validators.required, Validators.minLength(5)]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        this.passwordValidator(),
      ]),
      confirmPasswordOnAddUser: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
      ]),
      blocked: new FormControl(''),
    },
    {
      validators: [
        FieldMatchValidatior('password', 'confirmPasswordOnAddUser'),
      ],
    }
  );

  public editUserForm = new FormGroup({
    editFirstName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15),
    ]),
    editLastName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15),
    ]),
    editEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  public userRoleFormGroup = new FormGroup({
    roles: new FormControl('', [Validators.required]),
    accountNumber: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
    ]),
    commissionCode: new FormControl(''),
    companyCode: new FormControl('', [Validators.required]),
    branchPlant: new FormControl('', [Validators.required]),
    sbuCode: new FormControl('', [Validators.required]),
  });

  // Password Reset Form Group
  public passwordResetFormGroup = new FormGroup(
    {
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        this.passwordValidator(),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
      ]),
    },
    { validators: [FieldMatchValidatior('newPassword', 'confirmPassword')] }
  );

  public showClearSearchButton: boolean;

  public searchOptions: SearchSettingsModel = {
    operator: 'contains',
    ignoreCase: true,
    ignoreAccent: true,
  };

  public onUserGridActionBegin(args: any): void {
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'name'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'name') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'username'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'username') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'given_name'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'given_name') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'family_name'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'family_name') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'email'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'email') {
          col.operator = 'contains';
        }
      });
    }
  }

  public getSbu(): void {
    this.sbu.push(new Sbu('100', 'Outdoor Customer'));
  }

  public getBranchPlant(): void {
    this.branchplant.push(new BranchPlant('CDL1', 'CDL1'));
    this.branchplant.push(new BranchPlant('CDL2', 'CDL2'));
    this.branchplant.push(new BranchPlant('NRI1', 'NRI1'));
    this.branchplant.push(new BranchPlant('REN1', 'REN1'));
  }

  public getCompanyCode(): void {
    this.companycode.push(new CompanyCode('00015', 'Cascade Design Reno, LLC'));
    this.companycode.push(new CompanyCode('00030', 'Cascade Design, LTD'));
  }

  public getUsers() {
    this.dataService
      .getAll<User>(
        `UserManagement/GetAllUsers/${this.userService.userProfile.companyCode}`
      )
      .subscribe((data) => {
        this.userList = data;
      });
  }

  public getRoles() {
    this.dataService
      .getAll<Role>('UserManagement/GetAllRoles')
      .subscribe((data) => {
        this.userRoles = data;
      });
  }

  public getLoginStats(): void {
    this.dataService
      .getAll<LoginStats>('UserManagement/GetLoginStats')
      .subscribe((data) => {
        this.loginStats = data;
      });
  }

  public getBlockedUsers(): void {
    this.dataService
      .getAll<User>('UserManagement/GetBlockedUsers')
      .subscribe((data) => {
        this.blockedUsers = data;
      });
  }

  public getActiveUsers(): void {
    this.dataService
      .getAll<User>('UserManagement/GetActiveUsers')
      .subscribe((data) => {
        this.activeUsers = data;
      });
  }

  public getFailedLogins(): void {
    this.dataService
      .getAll<FailedLogin>('UserManagement/GetFailedLogins')
      .subscribe((data) => {
        this.failedLogins = data;
        this.failedLoginsGrid?.refresh();
      });
  }
  public onClickAddButton() {
    this.ejDialogAddUser.show();
  }

  public onClickSearchButton() {
    if (this.searchString.length > 0) {
      this.dataService
        .getAll<any>(
          `UserManagement/UserSearch/${this.searchString}/${this.userService.userProfile.companyCode}`
        )
        .subscribe((users) => {
          this.showClearSearchButton = true;
          this.userList = users;
        });
    } else {
      this._cache.showDialog.next(
        new NotificationMessage(
          DialogType.warning,
          'Invalid Input',
          'Please type search pharse'
        )
      );
    }
  }

  public onClickClearButton(): void {
    this.searchString = '';
    this.showClearSearchButton = false;
    this.userList = [];
  }

  public onChangeTextboxSearch(event: any) {
    this.searchString = event.target.value;
  }

  public onClickEditUserButton(event: any) {
    // set form vales
    this.editUserForm.patchValue({
      editFirstName: this.user.given_name,
      editLastName: this.user.family_name,
      editEmail: this.user.email,
    });
    this.ejDialogEditUser.show();
  }

  public onClickEditProfileButton(event: any) {
    this.dialogTitle = 'Edit Profile';
    this.assignAndEditButtonText = 'Save';

    this.userRoleFormGroup.patchValue({
      commissionCode: this.user.app_metadata?.commissionCode?.toString(),
      companyCode: this.user.app_metadata?.companyCode,
      branchPlant: this.user.app_metadata?.branchPlant,
      sbuCode: this.user.app_metadata?.sbuCode?.toString(),
      accountNumber: this.user.app_metadata?.accountNumber?.toString(),
    });

    let userId: string = this.user.user_id ?? '';

    this.getUserRole(userId).subscribe((data) => {
      if (data.id == 'rol_vjaS54eHa3WKV2XM') {
        this.isDealerRole = false;
      } else {
        this.isDealerRole = true;
      }
      this.dropdownlistUserRole.text = data.name;
      this.isRoleSelected = true;
      this.ejDialogAssignRole.show();
    });
  }

  public onClickAssignRoleButton() {
    this.ejDialogAssignRole.show();
  }

  public onClickPasswordResetButton(event: any) {
    this.ejDialogPasswordReset.show();
  }

  public onClickViewActivityLogsButton(event: any) {
    this.dataService
      .getAll<UserActivityLog>(
        `UserManagement/GetLoginActivityLog/${this.user.user_id}`
      )
      .subscribe((data) => {
        this.userActivityLogs = [];
        this.userActivityLogs = data;
        this.ejDialogLoginActivity.show();
      });
  }

  public onToolbarClickLoginActivityGrid(args: ClickEventArgs): void {
    if (args.item.id?.includes('excelexport')) {
      this.loginActivityGrid?.showSpinner();
      this.loginActivityGrid?.excelExport();
    }
  }

  public onLoginActivityGridExcelExportComplete(): void {
    this.loginActivityGrid?.hideSpinner();
  }

  // All Cancel Buttons here//
  public onAddUserDialogCancelButtonClick() {
    this.isRoleSelected = false;
    this.addUserForm.reset();
    this.ejDialogAddUser.hide();
  }

  public onEditUserDialogCancelButtonClick() {
    this.isRoleSelected = false;
    this.editUserForm.reset();
    this.ejDialogEditUser.hide();
  }

  public onAssignProfileDialogCancelButtonClick() {
    this.isRoleSelected = false;
    this.userRoleFormGroup.reset();
    this.ejDialogAssignRole.hide();
  }

  public onPasswordResetDialogCancelButtonClick() {
    this.passwordResetFormGroup.reset();
    this.ejDialogPasswordReset.hide();
  }

  public onLoginActivityDialogCancelButtonClick() {
    this.ejDialogLoginActivity.hide();
  }

  public onUserStatusUpdateCancelButtonClick() {}

  public onSaveEditUserDataButtonClick() {
    const editUser: EditUser = new EditUser(
      this.editUserForm.controls.editFirstName.value ?? '',
      this.editUserForm.controls.editLastName.value ?? '',
      this.editUserForm.controls.editEmail.value ?? ''
    );
    this.dataService
      .update<any>(`UserManagement/UpdateUser/${this.user.user_id}`, editUser)
      .subscribe((response) => {
        this.ejDialogEditUser.refresh();
        this.editUserForm.reset();
        this.ejDialogEditUser.hide();
        const json = JSON.parse(response.message);

        this.user.given_name = json.given_name;
        this.user.family_name = json.family_name;
        this.user.email = json.email;
        this.userGrid?.refresh();

        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Edit User',
            `User ${this.user.username} updated successfully`
          )
        );
      });
  }

  public onSubmitAssignUserProfile() {
    const userRole: UserRole = new UserRole(
      this.user.user_id,
      this.userRoleFormGroup.value.roles?.toString()
    );
    this.dataService
      .update<UserRole>(`UserManagement/UpdateUserRole`, userRole)
      .subscribe((response) => {
        //role was assigned. now set metadata.
        const profile: AppMetadata = new AppMetadata(
          Number(this.userRoleFormGroup.value.commissionCode),
          this.userRoleFormGroup.value.companyCode?.toString(),
          this.userRoleFormGroup.value.branchPlant?.toString(),
          Number(this.userRoleFormGroup.value.sbuCode),
          Number(this.userRoleFormGroup.value.accountNumber)
        );
        const serializedForm = JSON.stringify(profile);
        this.dataService
          .update<any>(
            `UserManagement/UpdateUserProfile/${this.user.user_id}`,
            serializedForm
          )
          .subscribe((httpResponse) => {
            const message = JSON.parse(httpResponse.message);
            const appMetaData: AppMetadata = new AppMetadata(
              message.commissionCode,
              message.companyCode,
              message.branchPlant,
              message.sbuCode,
              message.accountNumber
            );
            this.user.app_metadata = appMetaData;
            const index = this.userList.findIndex(
              (f) => f.user_id == this.user.user_id
            );
            this.userList[index].app_metadata = appMetaData;
            this.userRoleFormGroup.reset();
            this.ejDialogAssignRole.hide();
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.success,
                'Edit User',
                `User ${this.user.username} successfully edited`
              )
            );
          });
      });
  }

  public onSubmitResetPassword() {
    const passworReset: PasswordReset = new PasswordReset(
      this.passwordResetFormGroup.controls.newPassword.value ?? '',
      'Username-Password-Authentication'
    );
    this.dataService
      .update<any>(
        `UserManagement/ResetPassword/${this.user.user_id}`,
        passworReset
      )
      .subscribe((response) => {
        this.passwordResetFormGroup.reset();
        this.ejDialogPasswordReset.hide();

        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.success,
            'Reset Password',
            `Password successfully reset for ${this.user.username}`
          )
        );
      });
  }

  public tabSelected(e: SelectEventArgs): void {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }

  public addUserWizardButtonPressed(event: any) {
    switch (event.target.id) {
      case 'testingnext':
        (this.tab as TabComponent).enableTab(1, true);
        (this.tab as TabComponent).select(1);
        (this.tab as TabComponent).enableTab(0, false);
    }
  }

  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(
    event: any,
    userSwitch: any,
    data: User
  ) {
    const checked: boolean = event.checked;
    this.user = data;

    this.userSwitch = userSwitch;
    if (checked) {
      this.dialogUserStatusWarning = 'Enable';
      this.isUserStatusDialogNoButtonClicked = false;
    } else {
      this.dialogUserStatusWarning = 'Disable';
      this.isUserStatusDialogNoButtonClicked = true;
    }
    this.ejDialogUserStatus.show();
  }

  public onUserStatusDialogYesButtonClick() {
    this.isUserStatusDialogNoButtonClicked = false;
    let newUserState: boolean;

    if (this.user.blocked === true) {
      newUserState = false;
    } else {
      newUserState = true;
    }
    this.dataService
      .update(
        `UserManagement/SetUserStatus/${this.user.user_id}/${newUserState}`,
        null
      )
      .subscribe((data) => {
        this.ejDialogUserStatus.hide();
      });
  }

  public onUserStatusDialogNoButtonClick() {
    this.isUserStatusDialogNoButtonClicked = true;
    this.userSwitch.checked = !this.user.blocked;
    this.ejDialogUserStatus.hide();
  }
  // ********************************************************

  public onRolesDropDownSelect(args: any): void {
    this.isRoleSelected = true;
    if (args.itemData.name == 'Dealer') {
      this.isDealerRole = true;
    } else {
      this.isDealerRole = false;
    }
  }

  public onUserGridRowSelected(selectedUser: any): void {
    this.user = selectedUser;
  }

  public getUserRole(userId: string): Observable<Role> {
    return this.dataService.getById('UserManagement/GetUserRole', userId);
  }

  public onClickDeleteUserButton(event: any): void {
    this.deleteUserDialogTitle = `Delete User : ${this.user.username}`;
    this.dialogDeleteUser.show();
  }
  public deleteUser(): void {
    let userId: string = this.user.user_id ?? '';
    this.dataService
      .deleteById('UserManagement/DeleteUser', userId)
      .subscribe((data) => {
        this.userList = this.userList.filter((f) => f.user_id != userId);
        this.userGrid?.refresh();
      });
  }
  public onCloseDialogDeleteUser(): void {
    this.dialogDeleteUser.hide();
  }
  public onClickYesButtonOfUserConfirmationDeleteDialog(): void {
    this.deleteUser();
    this.dialogDeleteUser.hide();
  }

  public onClickNoButtonOfUserConfirmationDeleteDialog(): void {
    this.dialogDeleteUser.hide();
  }
}
