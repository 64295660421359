<ejs-dialog
  id="dialogEmailAddress"
  #dialogEmailAddress
  header="Email Address"
  [showCloseIcon]="true"
  [visible]="false"
  [isModal]="true"
  (close)="hide()"
  height="250px"
  width="300px"
>
  <ng-template #content>{{ emailAddressList }} </ng-template> </ejs-dialog
><ejs-dialog
  id="dialogEmailDeliveryLogDetails"
  #dialogEmailDeliveryLogDetails
  header="Email Delivery Log Details"
  [showCloseIcon]="true"
  [visible]="false"
  [isModal]="true"
  [enableResize]="true"
  (close)="hide()"
>
  <ng-template #content>
    <ejs-grid
      #gridEmailDeliveryLogDetails
      id="email-delivery-log-details"
      [allowSorting]="true"
      allowTextWrap="true"
      [allowResizing]="true"
      [dataSource]="emailDeliveryLogDetails"
      [toolbar]="toolbarEmailDeliveryLogGrid"
    >
      <e-columns>
        <e-column
          headerText="Log Id"
          field="id"
          [allowEditing]="false"
          type="string"
          width="100px"
        >
        </e-column>
        <e-column
          headerText="Job Id"
          field="notificationServiceJobId"
          type="string"
          width="100px"
          [visible]="false"
        >
        </e-column>
        <e-column
          headerText="Message Type"
          field="messageType"
          [allowEditing]="false"
          type="string"
          width="100px"
        ></e-column>
        <e-column
          headerText="Order Number"
          field="orderNumber"
          [allowEditing]="false"
          type="string"
          width="100px"
        >
        </e-column>
        <e-column
          headerText="Datestamp"
          field="datestamp"
          [allowEditing]="false"
          type="date"
          [format]="dateFormatOption"
          width="100px"
        ></e-column>
        <e-column
          headerText="Log Message"
          textAlign="left"
          field="logMessage"
          type="string"
          width="200px"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </ng-template>
</ejs-dialog>
<div>
  <h2>Portal Management</h2>
  <h3>Email Delivery</h3>
</div>
<div class="e-card" style="height: 500px">
  <div class="e-card-content">
    <ejs-accumulationchart
      id="pie"
      [legendSettings]="pielegendSettings"
      [tooltip]="pieTooltipSetting"
      [enableAnimation]="animation"
      [enableBorderOnMouseMove]="enableBorderOnMouseMove"
      [enableSmartLabels]="enableSmartLabels"
      (pointRender)="pointRender($event)"
      (load)="accumulationload($event)"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          [dataSource]="dataChart"
          xName="Product"
          yName="Percentage"
          innerRadius="50%"
          radius="75%"
          [palettes]="palettes"
          [dataLabel]="dataLabel"
          [startAngle]="startAngle"
          [endAngle]="endAngle"
          [border]="border"
        >
        </e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</div>
<br />
<!-- Email Delivery Log Gird-->
<div style="height: 500px; width: 100%">
  <ejs-grid
    id="emailDeliveryLogGrid"
    #emailDeliveryLogGrid
    [dataSource]="emailDeliveryLogList"
    height="100%"
    width="100%"
    enableStickyHeader="true"
    [allowResizing]="true"
    gridLines="Default"
    [allowSorting]="true"
    [allowFiltering]="true"
    [allowPaging]="false"
    allowTextWrap="true"
    [filterSettings]="filterOption"
    [loadingIndicator]="loadingIndicator"
    [allowPdfExport]="true"
    [allowExcelExport]="true"
    [allowResizing]="true"
    [toolbar]="toolbarEmailDeliveryLogGrid"
    [enableHover]="enableRowHover"
    [showColumnChooser]="true"
    (rowSelected)="onEmailDeliveryLogGridRowSelected($event.data)"
  >
    <e-columns>
      <e-column
        field="id"
        headerText="Id"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>

      <e-column
        field="dateCreated"
        headerText="Date Created"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
        type="date"
        [format]="dateFormatOption"
        [allowFiltering]="false"
      ></e-column>
      <e-column
        field="orderNumber"
        headerText="Order Number"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="companyCode"
        headerText="Company Code"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
        [visible]="false"
      ></e-column>
      <e-column
        field="sbuCode"
        headerText="SBU Code"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="messageId"
        headerText="Message Id"
        textAlign="Center"
        width="120"
      ></e-column>
      <e-column
        field="failedAttempts"
        headerText="Failed Attempts"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="documentType"
        headerText="Document Type"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column headerText="Action" maxWidth="130">
        <ng-template #template let-data>
          <ejs-menu
            target="#target"
            [items]="menuItems"
            showItemOnClick="true"
            (select)="onEmailDeliveryContextMenuSelect($event)"
          ></ejs-menu>
          <!-- <button
            ejs-button
            cssClass="e-success"
            #buttonView
            (click)="showDetails(data)"
          >
            View Details
          </button>
          <button
            ejs-button
            cssClass="e-primary"
            #buttonView
            style="float: right"
            (click)="showEmailDetails(data)"
          >
            Show Email
          </button> -->
        </ng-template></e-column
      >
    </e-columns>
  </ejs-grid>
</div>
