import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FieldMatchValidatior } from '../../shared/custom-validators/field-match.validator';
import { CreateUser } from '../../shared/model/create-user.model';
import { AppMetadata } from '../../shared/model/app-metadata.model';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Sbu } from '../../shared/model/sbu.model';
import { CompanyCode } from '../../shared/model/company-code.model';
import { BranchPlant } from '../../shared/model/branch-plant.model';
import { Role } from '../../shared/model/role.model';
import { User } from '../../shared/model/user.model';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { UserRole } from 'src/app/shared/model/user-role.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  public dropDownSbuFields: Object = { text: 'name', value: 'id' };
  public sbu: Sbu[];
  public companycode: CompanyCode[];
  public dropDownCompanyCodeFields: Object = { text: 'name', value: 'id' };
  public branchplant: BranchPlant[];
  public dropDownBranchPlantFields: Object = { text: 'name', value: 'id' };
  public userRoles: Role[];
  public dropDownUserRoleFields: Object = { text: 'name', value: 'id' };
  public isDealerRole?: boolean;
  public isRoleSelected: boolean;
  public isEmailVerified: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public validEmail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isUsernameVerified: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public validUsername: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public eyeIconToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public showPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public commissionCodeString: string;
  public accountNumberString: string;

  constructor(
    private dataService: DataserviceService,
    private router: Router,
    private _cache: CacheService
  ) {
    this.sbu = [];
    this.branchplant = [];
    this.companycode = [];
    this.userRoles = [];
    this.isRoleSelected = false;
    this.commissionCodeString = '';
    this.accountNumberString = '';
  }

  ngOnInit(): void {
    this.getRoles();
    this.getSbu();
    this.getBranchPlant();
    this.getCompanyCode();
  }

  // User Form Group
  public addUserForm = new FormGroup(
    {
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        this.passwordValidator(),
      ]),
      confirmPasswordOnAddUser: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
      ]),
      blocked: new FormControl(''),
      roles: new FormControl('', [Validators.required]),
      accountNumber: new FormControl('', {
        validators: [Validators.maxLength(6)],
      }),
      commissionCode: new FormControl('', {
        validators: [Validators.maxLength(6)],
      }),
      companyCode: new FormControl('', [Validators.required]),
      branchPlant: new FormControl('', [Validators.required]),
      sbuCode: new FormControl('', [Validators.required]),
    },
    {
      validators: [
        FieldMatchValidatior('password', 'confirmPasswordOnAddUser'),
        this.commissionCodeOrAccountNumberRequired(
          'accountNumber',
          'commissionCode'
        ),
      ],
    }
  );

  private passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value as string;

      // Define a regular expression pattern for the password criteria
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!@#()?])[A-Za-z\d_!@#()?*]{8,50}$/;

      if (!passwordPattern.test(password)) {
        // Password does not meet the criteria, so return an error
        return { invalidPassword: true };
      }

      // Password is valid
      return null;
    };
  }

  private commissionCodeOrAccountNumberRequired(
    field1: string,
    field2: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const comCode = control.get(field2);
      const accNum = control.get(field1);

      if (comCode?.value == null && accNum?.value == null) {
        return { commissionCodeOrAccountNumberRequired: true };
      }

      if (
        comCode?.value != null &&
        comCode?.value != '' &&
        comCode?.value != undefined
      ) {
        if (comCode?.value.toString().length == 6) {
          return null;
        }
      }

      if (
        accNum?.value != null &&
        accNum?.value != '' &&
        accNum?.value != undefined
      ) {
        if (accNum?.value.toString().length == 6) {
          return null;
        }
      }

      return { commissionCodeOrAccountNumberRequired: true };
    };
  }

  public validateEmail() {
    const email: string =
      this.addUserForm.get('email')?.value?.toString() ?? '';

    if (email) {
      this.dataService
        .getAll<User>(`UserManagement/ValidateEmail/${email}`)
        .subscribe((data) => {
          this.isEmailVerified.next(true);

          if (data.length == 0) {
            this.validEmail.next(true);
          } else {
            this.validEmail.next(false);
          }
        });
    }
  }

  public validateUsername() {
    const username: string =
      this.addUserForm.get('username')?.value?.toString() ?? '';

    if (username) {
      this.dataService
        .getAll<User>(`UserManagement/ValidateUsername/${username}`)
        .subscribe((data) => {
          this.isUsernameVerified.next(true);

          if (data.length == 0) {
            this.validUsername.next(true);
          } else {
            this.validUsername.next(false);
          }
        });
    }
  }

  // Gets for User forms
  get name() {
    return this.addUserForm.get('name');
  }
  get username() {
    return this.addUserForm.get('username');
  }
  get firstName() {
    return this.addUserForm.get('firstName');
  }
  get lastName() {
    return this.addUserForm.get('lastName');
  }
  get email() {
    return this.addUserForm.get('email');
  }
  get password() {
    return this.addUserForm.get('password');
  }
  get confirmPasswordOnAddUser() {
    return this.addUserForm.get('confirmPasswordOnAddUser');
  }
  get commissionCode() {
    return this.addUserForm.get('commissionCode');
  }
  get companyCode() {
    return this.addUserForm.get('companyCode');
  }
  get branchPlant() {
    return this.addUserForm.get('branchPlant');
  }
  get sbuCode() {
    return this.addUserForm.get('sbuCode');
  }
  get accountNumber() {
    return this.addUserForm.get('accountNumber');
  }
  get roles() {
    return this.addUserForm.get('roles');
  }

  public onSaveAddUserDataButtonClick() {
    const createdUser = new CreateUser(
      this.addUserForm.get('name')?.value?.toString(),
      this.addUserForm.get('username')?.value?.toString(),
      this.addUserForm.get('firstName')?.value?.toString(),
      this.addUserForm.get('lastName')?.value?.toString(),
      this.addUserForm.get('email')?.value?.toString(),
      this.addUserForm.get('password')?.value?.toString(),
      new AppMetadata(
        Number(this.addUserForm.get('commissionCode')?.value),
        this.addUserForm.get('companyCode')?.value?.toString(),
        this.addUserForm.get('branchPlant')?.value?.toString(),
        Number(this.addUserForm.get('sbuCode')?.value),
        Number(this.addUserForm.get('accountNumber')?.value)
      )
    );

    const serializedForm = JSON.stringify(createdUser);
    this.dataService
      .add<any>('UserManagement/AddUser', serializedForm)
      .subscribe((data) => {
        var user = JSON.parse(data.message);
        const userRole: UserRole = new UserRole(
          user.user_id,
          this.addUserForm.value.roles?.toString()
        );
        this.dataService
          .update<any>('UserManagement/UpdateUserRole', userRole)
          .subscribe((data) => {});
        this.router.navigate(['/user-management']);
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create User',
            `User created successfully`
          )
        );
      });
  }

  public getSbu(): void {
    this.sbu.push(new Sbu('100', 'Outdoor Customer'));
  }

  public getBranchPlant(): void {
    this.branchplant.push(new BranchPlant('CDL1', 'CDL1'));
    this.branchplant.push(new BranchPlant('CDL2', 'CDL2'));
    this.branchplant.push(new BranchPlant('NRI1', 'NRI1'));
    this.branchplant.push(new BranchPlant('REN1', 'REN1'));
  }

  public getCompanyCode(): void {
    this.companycode.push(new CompanyCode('00015', 'Cascade Design Reno, LLC'));
    this.companycode.push(new CompanyCode('00030', 'Cascade Design, LTD'));
  }

  public getRoles() {
    this.dataService
      .getAll<Role>('UserManagement/GetAllRoles')
      .subscribe((data) => {
        this.userRoles = data;
      });
  }

  public onRolesDropDownSelect(args: any): void {
    this.isRoleSelected = true;
    if (args.itemData.name == 'Dealer') {
      this.isDealerRole = true;
    } else {
      this.isDealerRole = false;
    }
  }

  public emailLabelChanged() {
    this.validEmail.next(false);
    this.isEmailVerified.next(false);
  }

  public usernameLabelChanged() {
    this.validUsername.next(false);
    this.isUsernameVerified.next(false);
  }

  public cancelButtonClicked() {
    this.router.navigate(['/user-management']);
  }

  public generatePassword() {
    // creates template with 2 characters from each required attribute
    var password = Array(8)
      .fill('0123456789', 0, 2)
      .fill('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 2, 4)
      .fill('abcdefghijklmnopqrstuvwxyz', 4, 6)
      .fill('_!@#()?', 6, 8)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join('');

    // Shuffles password
    var charArray = password.split('');
    var length = charArray.length;

    for (var i = 0; i < length; i++) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = charArray[i];
      charArray[i] = charArray[j];
      charArray[j] = temp;
    }
    password = charArray.join('');

    this.addUserForm.controls['password'].setValue(password);
    this.addUserForm.controls['confirmPasswordOnAddUser'].setValue(password);
    this.showPassword.next(true);
  }

  public toggleShowHidePassword() {
    if (this.showPassword.value) {
      this.showPassword.next(false);
    } else {
      this.showPassword.next(true);
    }
  }

  public hoverOnEyeIcon() {
    this.eyeIconToggle.next(true);
  }

  public hoverOffEyeIcon() {
    this.eyeIconToggle.next(false);
  }

  public keyUpCommissionCode() {
    this.commissionCodeString =
      this.addUserForm.get('commissionCode')?.value || '';
  }

  public keyUpAccountNumber() {
    this.accountNumberString =
      this.addUserForm.get('accountNumber')?.value || '';
  }
}
