import { Component, ViewChild } from '@angular/core';
import { OrderModificationSettings } from './model/order-modification-settings.model';
import {
  ActionEventArgs,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-order-modification-settings',
  templateUrl: './order-modification-settings.component.html',
  styleUrls: ['./order-modification-settings.component.scss'],
})
export class OrderModificationSettingsComponent {
  public orderModificationSettingsList: OrderModificationSettings[] = [];
  @ViewChild('orderModificationSettingsGrid')
  public orderModificationSettingsGrid?: GridComponent;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public editSettings?: EditSettingsModel;
  public toolbarOptions?: ToolbarItems[];
  public enableRowHover: boolean = false;

  constructor(
    private dataService: DataserviceService,
    private userService: UserService,
    private _cache: CacheService
  ) {}

  ngOnInit(): void {
    this.getOrderModificationSettings();
    this.editSettings = { allowEditing: true };
    this.toolbarOptions = ['Edit', 'Cancel', 'ColumnChooser', 'Search'];
  }

  public getOrderModificationSettings() {
    this.dataService
      .getAll<OrderModificationSettings>(
        `OrderModificationSettings/GetOrderModificationSettings/${this.userService.userProfile.companyCode}`
      )
      .subscribe((data) => {
        this.orderModificationSettingsList = data;
      });
  }

  public onActionCompleteOrderModificationSettings(
    args: ActionEventArgs
  ): void {
    if (args.requestType === 'save' && args.action === 'edit' && args.data) {
      const newData = args.data as {
        id: number;
        numberOfDaysPriorToPickDate: number;
      };
      const orderModificationSettings = new OrderModificationSettings(
        newData.id,
        new Date(),
        this.userService.userProfile.companyCode,
        newData.numberOfDaysPriorToPickDate,
        new Date(),
        this.userService.userProfile.name
      );
      this.dataService
        .update<any>(
          `OrderModificationSettings/UpdateOrderModificationSettings/${newData.id}/${this.userService.userProfile.companyCode}/${newData.numberOfDaysPriorToPickDate}`,
          orderModificationSettings
        )
        .subscribe((response) => {
          this._cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Update Order Modification Settings',
              `Order Modification Settings updated successfully`
            )
          );
        });
    }
  }
}
