import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { UserService } from 'src/app/services/user.service';
import { enableRipple } from '@syncfusion/ej2-base';
import { Router } from '@angular/router';
import appVersion from '../../../package.json';
import { MenuItemModel } from '@syncfusion/ej2-angular-navigations';
enableRipple(true);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('liUserManagement') public liUserManagement!: ElementRef;
  @ViewChild('liPortalManagement') public liPortalManagement!: ElementRef;
  @ViewChild('liSalesForce') public liSalesForce!: ElementRef;
  @ViewChild('liOrderMinimum') public liOrderMinimum!: ElementRef;
  @ViewChild('liEmailDeliveryLog') public liEmailDeliveryLog!: ElementRef;
  @ViewChild('liReportConfig') public liReportConfig!: ElementRef;
  @ViewChild('liApiLog') public liApiLog!: ElementRef;

  public version: string = appVersion.version;
  showPortalDropdown: boolean = false;
  public profileMenu: ItemModel[] = [
    {
      text: 'Logout',
    },
    {
      text: `Version: ${this.version}`,
    },
  ];

  constructor(public _router: Router, public userService: UserService) {}

  ngOnInit(): void {}
  public menuItems: MenuItemModel[] = [
    {
      text: 'User Management',
      url: 'user-management',
    },
    {
      text: 'Portal Management',
      items: [
        { text: 'Order Minimum', url: 'order-minimum' },
        { text: 'Order Confirmation Log', url: 'email-delivery-log' },
        { text: 'Report Configuration', url: 'report-configuration' },
        { text: 'Metrics', url: 'api-log' },
        {
          text: 'Order Modification Settings',
          url: 'order-modification-settings',
        },
        { text: 'Settings', url: 'settings' },
      ],
    },
    {
      text: 'SalesForce',
      url: 'sales-force',
    },
    {
      text: 'IPlanner',
      url: 'iplanner',
    },
  ];
  togglePortalDropdown() {
    this.showPortalDropdown = !this.showPortalDropdown;
  }
  public onLogoutButtonClick(args: MenuEventArgs) {
    const selectedOption = args.item.text?.toString()?.toLowerCase();
    if (selectedOption == 'logout') {
      this.userService.logout();
    }
  }
}
