<div style="display: flex; flex-direction: row; margin-top: 20px">
  <div style="order: 1; flex: 15">
    <div style="position: sticky; position: -webkit-sticky; top: 0">
      <div class="e-section-control">
        <!-- To render ButtonGroup. -->
        <div class="e-btn-group e-vertical" style="width: 15vw">
          <span class="e-btn">
            <img
              src="../../../assets/img/headset-customer-support.png"
              height="50px"
              width="50px"
              alt=""
              style="
                display: inline-block;
                vertical-align: middle;
                border: none;
              "
            />
            <label
              style="
                font-size: 1.7em;
                display: inline-block;
                vertical-align: left;
              "
              >Help Center</label
            >
          </span>
          <a href="/dashboard" class="e-btn"
            ><span class="e-icons e-arrow-left"></span> Back to Dashboard</a
          >
          <a class="e-btn" (click)="scrollToAnchor('creatingAUser')"
            >Creating a User</a
          >
          <a class="e-btn" (click)="scrollToAnchor('editingAUser')"
            >Editing a User</a
          >
          <a class="e-btn" (click)="scrollToAnchor('editingAProfile')"
            >Editing a Profile</a
          >
          <a class="e-btn" (click)="scrollToAnchor('disablingAUser')"
            >Disabling a User</a
          >
          <a class="e-btn" (click)="scrollToAnchor('deletingAUser')"
            >Deleting a User</a
          >
          <a class="e-btn" (click)="scrollToAnchor('resettingAPassword')"
            >Resetting a Password</a
          >
          <a class="e-btn" (click)="scrollToAnchor('viewingLoginActivity')"
            >Viewing Login Activity</a
          >
          <a class="e-btn" href="mailto:clientservices@cascadedesigns.com">
            <label style="font-size: 1.7em">Contact Us</label>
            <p>For customer or technical support:</p>
            <p>clientservices@CascadeDesigns.com</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="e-card"
    style="order: 2; flex: 85; margin-left: 20px; overflow: visible"
  >
    <div
      class="e-card-content"
      style="
        overflow: visible;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      "
    >
      <!--Create User-->
      <div
        style="order: 1; display: flex; flex-direction: row; flex-wrap: wrap"
        id="creatingAUser"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Creating a User</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click this button to begin the
                process of creating a user.
              </li>
              <li>
                Fill in the fields following the requirements. The username must
                be unique. The email likewise must be unique. Any fields that
                aren't corrent or aren't unique will be highlighted in red.
              </li>
              <li>Choose the role as either Dealer or Sales Rep.</li>
              <li>
                If you are assigning the Sales Rep role to the user, then fill
                in the information below and click the Finish button. You will
                be redirected back to the dashboard where you can verify the
                successful creation of a user by searching for the username.
              </li>
              <li>
                The process of assigning the Dealer role to a user is similar,
                fill out the information below, then click the Finish button. Be
                sure to verify that the user was successfully created by
                searching for them in the dashboard.
              </li>
              <li>
                After confirming, you will be directed back to the dashboard
                where you can confirm the creation of this user.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 2;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            style="order: 1"
            src="../../../assets/img/userManagementDocumentation/AddUser1.png"
            alt="Click the dashboard button labelled new user."
            width="435"
          />
          <img
            style="order: 2"
            src="../../../assets/img/userManagementDocumentation/AddUser2.png"
            alt="Enter general information into each input and click next."
            width="435"
          />
          <img
            style="order: 3"
            src="../../../assets/img/userManagementDocumentation/AddUserChooseRole.png"
            alt="Choose the role of the new user.  The options are either Dealer or Sales Rep"
            width="435"
          />
          <img
            style="order: 4"
            src="../../../assets/img/userManagementDocumentation/AddUser3-sales-rep.png"
            alt=""
            width="435"
          />
          <img
            style="order: 5"
            src="../../../assets/img/userManagementDocumentation/AddUser3-dealer.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--Edit User-->
      <div
        style="order: 2; display: flex; flex-direction: row; flex-wrap: wrap"
        id="editingAUser"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Edit a User</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click the Edit User button to
                begin the process of editing a user.
              </li>
              <li>
                Edit whichever field necessary for your user. Then click the
                Save button to save changes to the user. You will be redirected
                back to the dashboard, search for the user there to verify the
                changes have successfully taken place.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            style="order: 2"
            src="../../../assets/img/userManagementDocumentation/actionMenu.png"
            alt=""
            width="435"
          />
          <img
            style="order: 2"
            src="../../../assets/img/userManagementDocumentation/editUser.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--Edit Profile-->
      <div
        style="order: 3; display: flex; flex-direction: row; flex-wrap: wrap"
        id="editingAProfile"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Edit a Profile</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click the Edit Profile button to
                begin the process of editing a profile.
              </li>
              <li>
                Edit whichever field necessary for your user. Then click the
                Save button to save changes to the user. You will be redirected
                back to the dashboard, search for the user there to verify the
                changes have successfully taken place.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            style="order: 2"
            src="../../../assets/img/userManagementDocumentation/actionMenu.png"
            alt=""
            width="435"
          />
          <img
            style="order: 2"
            src="../../../assets/img/userManagementDocumentation/editProfile.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--Disable User-->
      <div
        style="order: 4; display: flex; flex-direction: row; flex-wrap: wrap"
        id="disablingAUser"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Disable User</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click this switch to disable a
                user, click it again to enable them.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            src="../../../assets/img/userManagementDocumentation/disableUser.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--Delete User-->
      <div
        style="order: 5; display: flex; flex-direction: row; flex-wrap: wrap"
        id="deletingAUser"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Delete User</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click the Delete User button to
                begin the process of deleting a user.
              </li>
              <li>Confirm your selection.</li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            src="../../../assets/img/userManagementDocumentation/actionMenu.png"
            alt=""
            width="435"
          />
          <img
            src="../../../assets/img/userManagementDocumentation/deleteUser.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--Reset a User's Password-->
      <div
        style="order: 6; display: flex; flex-direction: row; flex-wrap: wrap"
        id="resettingAPassword"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>Reset a User's Password</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click the Password Reset button
                to begin the process of resetting a password.
              </li>
              <li>
                Enter a new password for the user and follow the guidelines in
                red. Upon clicking the Reset Password button, the password will
                be reset. Be sure to confirm this with the user of the account.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            src="../../../assets/img/userManagementDocumentation/actionMenu.png"
            alt=""
            width="435"
          />
          <img
            src="../../../assets/img/userManagementDocumentation/resetPassword.png"
            alt=""
            width="435"
          />
        </div>
      </div>

      <!--View Login Activity-->
      <div
        style="order: 7; display: flex; flex-direction: row; flex-wrap: wrap"
        id="viewingLoginActivity"
      >
        <div style="order: 1; flex: 50">
          <div
            style="
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              padding-top: 20px;
              padding-bottom: 20px;
            "
          >
            <h1>View Login Activity</h1>
            <ol>
              <li>
                Navigate to the Dashboard page. Click the Login Activity button
                to begin the process of viewing the activity of a user on
                Partners.
              </li>
              <li>
                This log contains information pertaining to the logins
                associated with a user.
              </li>
            </ol>
          </div>
        </div>
        <div
          style="
            order: 1;
            flex: 50;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <img
            src="../../../assets/img/userManagementDocumentation/actionMenu.png"
            alt=""
            width="435"
          />
          <img
            src="../../../assets/img/userManagementDocumentation/activityLog.png"
            alt=""
            width="435"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<br />
