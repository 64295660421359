<div>
  <h2>Portal Management</h2>
  <h3>Report Configuration Settings</h3>
</div>
<div style="display: flex; gap: 10px">
  <!--DataType Grid-->
  <div class="e-card" style="width: 33%">
    <div class="e-card-content">
      <h2 style="order: 1">Data Type List</h2>
      <ejs-grid
        id="dataTypeSettingsGrid"
        #dataTypeSettingsrid
        [dataSource]="dataTypeSettingsList"
        height="400px"
        width="100%"
        [allowResizing]="true"
        [allowSorting]="true"
        [allowFiltering]="true"
        [allowPaging]="false"
        allowTextWrap="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        [toolbar]="toolbarOptions"
        [editSettings]="editSettings"
        (actionComplete)="onActionCompleteDataType($event)"
        [showColumnChooser]="true"
        [enableHover]="enableRowHover"
      >
        <e-columns>
          <e-column
            field="dateCreated"
            headerText="Date Created"
            width="130"
            clipMode="EllipsisWithTooltip"
            [format]="dateFormatOption"
            type="date"
            [visible]="false"
          ></e-column>
          <e-column
            id="typeData"
            field="typeData"
            headerText="Data Type"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            id="isaActive"
            field="isActive"
            headerText="Is Active"
            textAlign="Center"
            width="100"
            [allowFiltering]="false"
            ><ng-template #template let-data>
              <ejs-switch
                id="change-userstatus-switch"
                #userStatusSwitch
                (change)="
                  onChangeUserStatusSwitchClick($event, userStatusSwitch)
                "
                [checked]="data.isActive"
              ></ejs-switch> </ng-template></e-column
          ><e-column
            field="dateModified"
            headerText="Date Modified"
            textAlign="Center"
            width="130"
            type="date"
            [format]="dateFormatOption"
            clipMode="EllipsisWithTooltip"
            [allowFiltering]="false"
            [visible]="false"
          ></e-column>
          <e-column
            field="createdOrModifiedBy"
            headerText="Created Or Modified By"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
  <!--TextAlign Grid-->
  <div class="e-card" style="width: 33%">
    <div class="e-card-content">
      <h2 style="order: 1">Text Align List</h2>
    </div>
    <ejs-grid
      id="textAlignSettingsGrid"
      #textAlignSettingsGrid
      [dataSource]="textAlignSettingsList"
      height="400px"
      width="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      allowTextWrap="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [toolbar]="toolbarOptions"
      [editSettings]="editSettings"
      (actionComplete)="onActionCompleteTextAlign($event)"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
    >
      <e-columns>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          field="textAlign"
          headerText="Text Align"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              (change)="onChangeUserStatusSwitchClick($event, userStatusSwitch)"
              [checked]="data.isActive"
            ></ejs-switch> </ng-template></e-column
        ><e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="130"
          type="date"
          [visible]="false"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
  <!--Format Grid-->
  <div class="e-card" style="width: 33%">
    <div class="e-card-content">
      <h2 style="order: 1">Format List</h2>
      <ejs-grid
        id="formatSettingsGrid"
        #formatSettingsGrid
        [dataSource]="formatSettingsList"
        height="400px"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [allowPaging]="false"
        allowTextWrap="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        [toolbar]="toolbarOptions"
        [editSettings]="editSettings"
        (actionComplete)="onActionCompleteFormat($event)"
        [showColumnChooser]="true"
        [enableHover]="enableRowHover"
      >
        <e-columns>
          <e-column
            field="dateCreated"
            headerText="Date Created"
            width="130"
            clipMode="EllipsisWithTooltip"
            [format]="dateFormatOption"
            type="date"
            [visible]="false"
          ></e-column>
          <e-column
            field="format"
            headerText="Format"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="isActive"
            headerText="Is Active"
            textAlign="Center"
            width="100"
            [allowFiltering]="false"
            ><ng-template #template let-data>
              <ejs-switch
                id="change-userstatus-switch"
                #userStatusSwitch
                (change)="
                  onChangeUserStatusSwitchClick($event, userStatusSwitch)
                "
                [checked]="data.isActive"
              ></ejs-switch> </ng-template
          ></e-column>
          <e-column
            field="dateModified"
            headerText="Date Modified"
            width="130"
            clipMode="EllipsisWithTooltip"
            [format]="dateFormatOption"
            type="date"
            [visible]="false"
          ></e-column>
          <e-column
            field="createdOrModifiedBy"
            headerText="Created Or Modified By"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
