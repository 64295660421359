<div>
  <h2>Portal Management</h2>
  <h3>Order Modification Settings</h3>
</div>
<div style="height: 500px; width: 100%">
  <ejs-grid
    id="orderModificationSettingsLogGrid"
    #orderModificationSettingsLogGrid
    [dataSource]="orderModificationSettingsList"
    height="100%"
    width="100%"
    enableStickyHeader="true"
    [allowResizing]="true"
    gridLines="Default"
    [allowSorting]="true"
    [allowFiltering]="true"
    [allowPaging]="false"
    allowTextWrap="true"
    [filterSettings]="filterOption"
    [loadingIndicator]="loadingIndicator"
    [allowPdfExport]="true"
    [allowExcelExport]="true"
    [allowResizing]="true"
    [toolbar]="toolbarOptions"
    [showColumnChooser]="true"
    [enableHover]="enableRowHover"
    [editSettings]="editSettings"
    (actionComplete)="onActionCompleteOrderModificationSettings($event)"
  >
    <e-columns>
      <e-column
        field="id"
        headerText="Id"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="dateCreated"
        headerText="Date Created"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
        type="date"
        [format]="dateFormatOption"
        [allowFiltering]="false"
      ></e-column>
      <e-column
        field="companyCode"
        headerText="Company Code"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="numberOfDaysPriorToPickDate"
        headerText="Number Of Days Prior To PickDate"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
      ></e-column>
      <e-column
        field="dateModified"
        headerText="Date Modified"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
        [visible]="false"
      ></e-column>
      <e-column
        field="createdOrModifiedBy"
        headerText="Created Or Modified By"
        textAlign="Center"
        width="120"
        clipMode="EllipsisWithTooltip"
        [visible]="false"
      ></e-column>
    </e-columns>
  </ejs-grid>
</div>
