// sales-force.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-force',
  templateUrl: './sales-force.component.html',
  styleUrls: ['./sales-force.component.scss'],
})
export class SalesForceComponent {
  public selectedItemValue: string = '';

  public onSalesforceItemSelected(selectedItem: string): void {
    this.selectedItemValue = selectedItem;
  }
}
