import { default as data } from '../../package.json';

export const environment = {
  appVersion: data.version,
  production: false,
  apiUrl: 'https://usermanagementapi.cascadedesigns.com/api',
  authDomain: 'rex-login-prod01.cascadedesigns.com',
  authAudience: 'https://usermanagementapi.cascadedesigns.com',
  authClientId: '1v9FNAwWz1beh8o3wjQ1z1oZzyVMsT7n',
  authScope: 'openid profile',
  appInsights: {
    instrumentationKey:
      'InstrumentationKey=4fefb77a-47a6-47b7-a841-9c2dae888bbb;IngestionEndpoint=https://westus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
  },
};
