<form [formGroup]="addUserForm" class="addUserFormClass">
  <div>
    <label for="name">Company Name: </label>
    <input
      id="name"
      class="e-input"
      type="text"
      placeholder="Enter Company Name"
      formControlName="name"
    />
    <div
      *ngIf="name?.invalid && (name?.dirty || name?.touched)"
      class="form-error"
    >
      <div *ngIf="name?.errors?.['required']">* Company Name is required.</div>
      <div *ngIf="name?.errors?.['minlength']">
        * Company Name must be at least 3 characters long.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="user-name">Username: </label>
    <div
      style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
    >
      <div class="e-input-group" style="order: 1">
        <input
          id="user-name"
          class="e-input"
          type="text"
          placeholder="Enter User Name"
          formControlName="username"
          (keyup)="usernameLabelChanged()"
        />
        <img
          id="eyeIconStatic"
          *ngIf="
            (username?.invalid && (username?.dirty || username?.touched)) ||
            (username?.touched &&
              (!isUsernameVerified.value || !validUsername.value))
          "
          src="../../../assets/img/icons/RedExclamation.gif"
          height="20px"
          width="20px"
          alt="Show or hide password"
        />
        <img
          id="eyeIconLooped"
          *ngIf="
            username?.touched && isUsernameVerified.value && validUsername.value
          "
          src="../../../assets/img/icons/GreenCheckMark.gif"
          height="20px"
          width="20px"
          alt="Show or hide password"
        />
      </div>

      <button
        id="verifyUsernameButton"
        class="e-control e-btn e-success sendButton"
        data-ripple="true"
        (click)="validateUsername()"
        style="order: 2"
      >
        Verify Username
      </button>
    </div>

    <div
      *ngIf="
        (username?.invalid && (username?.dirty || username?.touched)) ||
        (username?.touched &&
          (!isUsernameVerified.value || !validUsername.value))
      "
      class="form-error"
    >
      <div *ngIf="username?.errors?.['required']">* Username is required.</div>
      <div *ngIf="username?.errors?.['minlength']">
        * Username must be at least 5 characters long.
      </div>
      <div *ngIf="!isUsernameVerified.value && !validUsername.value">
        * To continue, please verify username.
      </div>
      <div *ngIf="isUsernameVerified.value && !validUsername.value">
        * This username has already been used, please try a different username.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="first-name">First Name: </label>
    <input
      id="first-name"
      class="e-input"
      type="text"
      placeholder="Enter First Name"
      formControlName="firstName"
    />
    <div
      *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)"
      class="form-error"
    >
      <div *ngIf="firstName?.errors?.['required']">
        * firstName is required.
      </div>
      <div *ngIf="firstName?.errors?.['minlength']">
        * firstName must be at least 2 characters long.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="last-name">Last Name: </label>
    <input
      id="last-name"
      class="e-input"
      type="text"
      placeholder="Enter Last Name"
      formControlName="lastName"
    />
    <div
      *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)"
      class="form-error"
    >
      <div *ngIf="lastName?.errors?.['required']">* lastName is required.</div>
      <div *ngIf="lastName?.errors?.['minlength']">
        * lastName must be at least 2 characters long.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="user-email">Email: </label>
    <div
      style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
    >
      <div class="e-input-group" style="order: 1">
        <input
          id="user-email"
          class="e-input"
          type="email"
          placeholder="Enter Email"
          formControlName="email"
          (keyup)="emailLabelChanged()"
        />
        <img
          id="eyeIconStatic"
          *ngIf="
            (email?.invalid && (email?.dirty || email?.touched)) ||
            (email?.touched && (!isEmailVerified.value || !validEmail.value))
          "
          src="../../../assets/img/icons/RedExclamation.gif"
          height="20px"
          width="20px"
          alt="Show or hide password"
        />
        <img
          id="eyeIconLooped"
          *ngIf="email?.touched && isEmailVerified.value && validEmail.value"
          src="../../../assets/img/icons/GreenCheckMark.gif"
          height="20px"
          width="20px"
          alt="Show or hide password"
        />
      </div>

      <button
        id="verifyEmailButton"
        class="e-control e-btn e-success sendButton"
        data-ripple="true"
        (click)="validateEmail()"
        style="order: 2"
      >
        Verify Email
      </button>
    </div>
    <div
      *ngIf="
        (email?.invalid && (email?.dirty || email?.touched)) ||
        (email?.touched && (!isEmailVerified.value || !validEmail.value))
      "
      class="form-error"
    >
      <div *ngIf="email?.errors?.['required']">* email is required.</div>
      <div *ngIf="email?.errors?.['email']">* Please Enter a Valid email.</div>
      <div *ngIf="!isEmailVerified.value && !validEmail.value">
        * To continue, please verify email.
      </div>
      <div *ngIf="isEmailVerified.value && !validEmail.value">
        * This email has already been used, please try a different email.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="password">New Password: </label>
    <div
      style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
    >
      <div class="e-input-group" style="order: 1">
        <input
          id="password"
          class="e-input"
          type="{{ showPassword.value ? 'text' : 'password' }}"
          placeholder="Enter Passsword"
          formControlName="password"
        />
        <div
          (click)="toggleShowHidePassword()"
          width="30px"
          height="30px"
          (mouseenter)="hoverOnEyeIcon()"
          (mouseleave)="hoverOffEyeIcon()"
        >
          <img
            id="eyeIconStatic"
            *ngIf="!eyeIconToggle.value"
            src="../../../assets/img/icons/EyeIconStatic.png"
            height="20px"
            width="20px"
            alt="Show or hide password"
          />
          <img
            id="eyeIconLooped"
            *ngIf="eyeIconToggle.value"
            src="../../../assets/img/icons/EyeIconLooped.gif"
            height="20px"
            width="20px"
            alt="Show or hide password"
          />
        </div>
      </div>

      <button
        id="generatePassword"
        class="e-control e-btn e-success sendButton"
        data-ripple="true"
        (click)="generatePassword()"
        style="order: 3"
      >
        Generate Password
      </button>
    </div>
    <div
      *ngIf="password?.invalid && (password?.dirty || password?.touched)"
      class="form-error"
    >
      <div *ngIf="password?.errors?.['required']">* Password is required.</div>
      <div *ngIf="password?.errors?.['minlength']">
        * Password must be at least 8 characters long.
      </div>
      <div *ngIf="password?.errors?.['invalidPassword']">
        * Password should be alphanumeric, contain a special character, and have
        at least one uppercase letter.
      </div>
    </div>
  </div>
  <br /><br />
  <div>
    <label for="confirm-password-on-add-user">Confirm Password: </label>
    <input
      id="confirm-password-on-add-user"
      class="e-input"
      type="password"
      placeholder="Confirm Passsword"
      formControlName="confirmPasswordOnAddUser"
    />
    <div
      *ngIf="
        confirmPasswordOnAddUser?.invalid &&
        (confirmPasswordOnAddUser?.dirty || confirmPasswordOnAddUser?.touched)
      "
      class="form-error"
    >
      <div *ngIf="confirmPasswordOnAddUser?.errors?.['required']">
        * confirmPassword is required.
      </div>
      <div *ngIf="confirmPasswordOnAddUser?.errors?.['minlength']">
        * confirmPassword must be at least 8 characters long.
      </div>
      <div *ngIf="!addUserForm.valid">
        * new Passsword and Confirm Password didn't match.
      </div>
    </div>
  </div>
  <br /><br />

  <label for="user-role">Roles: </label>
  <ejs-dropdownlist
    id="user-role"
    #dropdownlistUserRole
    [dataSource]="userRoles"
    [fields]="dropDownUserRoleFields"
    placeholder="Select Role"
    formControlName="roles"
    (select)="onRolesDropDownSelect($event)"
  ></ejs-dropdownlist>
  <div
    *ngIf="roles?.invalid && (roles?.dirty || roles?.touched)"
    class="form-error"
  >
    <div *ngIf="roles?.errors?.['required']">
      * please select a role from the dropdown.
    </div>
  </div>
  <br />
  <div *ngIf="isRoleSelected">
    <div *ngIf="isDealerRole">
      <label for="account-number">Account Number: </label>
      <input
        id="account-number"
        class="e-input"
        type="number"
        placeholder="Enter Account Number"
        formControlName="accountNumber"
        (keyup)="keyUpAccountNumber()"
      />
      <div
        *ngIf="
          accountNumber?.invalid &&
          (accountNumber?.dirty || accountNumber?.touched)
        "
        class="form-error"
      >
        <div *ngIf="accountNumber?.errors?.['required']">
          * accountNumber is required.
        </div>
        <div *ngIf="accountNumber?.errors?.['maxlength']">
          * Account number must be at 6 characters long.
        </div>
      </div>
      <br /><br />
    </div>

    <div *ngIf="!isDealerRole">
      <label for="commission-code">Commission Code: </label>
      <input
        id="commission-code"
        class="e-input"
        type="number"
        placeholder="Enter Commission Code"
        formControlName="commissionCode"
        (keyup)="keyUpCommissionCode()"
      />
      <div
        *ngIf="
          commissionCode?.invalid &&
          (commissionCode?.dirty || commissionCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="commissionCode?.errors?.['required']">
          * commissionCode is required.
        </div>
      </div>
      <br /><br />
    </div>

    <label for="company-code">Company Code: </label>
    <ejs-dropdownlist
      id="company-code"
      class="e-input"
      placeholder="Select Company Code"
      formControlName="companyCode"
      [dataSource]="companycode"
      [fields]="dropDownCompanyCodeFields"
    ></ejs-dropdownlist>

    <div
      *ngIf="
        companyCode?.invalid && (companyCode?.dirty || companyCode?.touched)
      "
      class="form-error"
    >
      <div *ngIf="companyCode?.errors?.['required']">
        * companyCode is required.
      </div>
    </div>
    <br /><br />

    <label for="branch-plant">Branch Plant: </label>
    <ejs-dropdownlist
      id="branch-plant"
      class="e-input"
      placeholder="Select Branch Plan"
      formControlName="branchPlant"
      [dataSource]="branchplant"
      [fields]="dropDownBranchPlantFields"
    ></ejs-dropdownlist>
    <div
      *ngIf="
        branchPlant?.invalid && (branchPlant?.dirty || branchPlant?.touched)
      "
      class="form-error"
    >
      <div *ngIf="branchPlant?.errors?.['required']">
        * branchPlant is required.
      </div>
    </div>
    <br /><br />

    <label for="sbu-code">SBU Code: </label>
    <ejs-dropdownlist
      id="sbu-code"
      class="e-input"
      placeholder="Select SBU Code"
      formControlName="sbuCode"
      [dataSource]="sbu"
      [fields]="dropDownSbuFields"
    ></ejs-dropdownlist>
    <div
      *ngIf="sbuCode?.invalid && (sbuCode?.dirty || sbuCode?.touched)"
      class="form-error"
    >
      <div *ngIf="sbuCode?.errors?.['required']">* sbuCode is required.</div>
    </div>
    <br /><br />
  </div>

  <button
    id="assignUserProfileCancelButton"
    class="e-control e-btn e-warning sendButton"
    data-ripple="true"
    (click)="cancelButtonClicked()"
    style="margin-right: 10px"
  >
    Cancel
  </button>
  <button
    id="assigneUserProfileSaveButton"
    class="e-control e-btn e-success sendButton"
    data-ripple="true"
    [disabled]="!addUserForm.valid || !validEmail.value || !validUsername.value"
    (click)="onSaveAddUserDataButtonClick()"
  >
    Save
  </button>
</form>
