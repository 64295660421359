import { Component} from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})

export class HelpCenterComponent {
  constructor(private viewportScroller: ViewportScroller) {}
  public scrollToAnchor(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
