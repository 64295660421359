import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public onloadBoolean1: boolean;
  public onloadBoolean2: boolean;
  public onloadBoolean3: boolean;

  constructor() {
    this.onloadBoolean1 = true;
    this.onloadBoolean2 = true;
    this.onloadBoolean3 = true;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.onloadBoolean1 = false;
    }, 50);

    setTimeout(() => {
      this.onloadBoolean2 = false;
    }, 200);

    setTimeout(() => {
      this.onloadBoolean3 = false;
    }, 450);
  }
}
