import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActionEventArgs,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  ClickEventArgs,
} from '@syncfusion/ej2-angular-navigations';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Router } from '@angular/router';
import { OrderMinimum } from './model/order-minimum.model';
import { User } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/services/user.service';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';

@Component({
  selector: 'app-order-minimum',
  templateUrl: './order-minimum.component.html',
  styleUrls: ['./order-minimum.component.scss'],
})
export class OrderMinimumComponent {
  public orderMinimumList: OrderMinimum[] = [];
  public orderYearList: OrderMinimum[] = [];
  @ViewChild('orderMinimumGrid') public orderMinimumGrid!: GridComponent;
  @ViewChild('ejDialogAddOrder') ejDialogAddOrder!: DialogComponent;
  @ViewChild('ejDialogCloneOrder') ejDialogCloneOrder!: DialogComponent;
  @ViewChild('ejDialogEditUser') ejDialogEditUser!: DialogComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;

  public userSwitch: any;

  // Declared  Variables
  public dialogTitle: string;
  public user: User;
  public dateCreatedValue = new Date();
  public dialogUserStatusWarning: string = '';
  public isUserStatusDialogNoButtonClicked: boolean = false;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public toolbarOrderMinimumGrid?: ToolbarItems[] | object;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public editSettings?: EditSettingsModel;
  public enableRowHover: boolean = false;
  public dropdownlistOrderYearSearchFields: Object = {
    text: 'orderYear',
    value: 'orderYear',
  };

  get dateCreated() {
    return this.addOrderForm.get('dateCreated');
  }
  get companyCode() {
    return this.addOrderForm?.get('companyCode');
  }
  get currencyCode() {
    return this.addOrderForm.get('currencyCode');
  }
  get countryCode() {
    return this.addOrderForm.get('countryCode');
  }
  get orderMinimumAmount() {
    return this.addOrderForm.get('orderMinimumAmount');
  }
  get handlingCharge() {
    return this.addOrderForm.get('handlingCharge');
  }
  get orderYear() {
    return this.addOrderForm.get('orderYear');
  }

  public addOrderForm = new FormGroup({
    dateCreated: new FormControl([Validators.required]),
    companyCode: new FormControl([
      Validators.required,
      Validators.minLength(3),
    ]),
    currencyCode: new FormControl([
      Validators.required,
      Validators.minLength(3),
    ]),

    countryCode: new FormControl([
      Validators.required,
      Validators.minLength(3),
    ]),
    orderMinimumAmount: new FormControl('', [Validators.required]),
    handlingCharge: new FormControl('', [Validators.required]),
    orderYear: new FormControl('', [Validators.required]),
  });
  public cloneOrderForm = new FormGroup({
    orderYear: new FormControl('', [Validators.required]),
  });
  public showClearSearchButton: boolean;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService,
    private userService: UserService
  ) {
    this.showClearSearchButton = false;
    this.dialogTitle = '';
    this.user = new User();
  }

  ngOnInit(): void {
    this.toolbarOrderMinimumGrid = [
      'Add',
      'Edit',
      'Cancel',
      {
        text: 'Clone',
        tooltipText: 'Clone',
        prefixIcon: 'e-expand',
        id: 'Clone',
      },
      'ColumnChooser',
      'Search',
    ];
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
    };
    this.getOrderMinimum();
  }
  public onActionCompleteOrderMinimum(args: ActionEventArgs): void {
    if (args.requestType === 'save' && args.action === 'add' && args.data) {
      const newData = args.data as {
        currencyCode: string;
        countryCode: string;
        orderMinimumAmount: number;
        handlingCharge: number;
        orderYear: number;
        isActive: boolean;
      };
      const addOrderMinimum = new OrderMinimum(
        0,
        new Date(),
        this.userService.userProfile.companyCode,
        newData.currencyCode,
        newData.countryCode,
        newData.orderMinimumAmount,
        newData.handlingCharge,
        newData.orderYear,
        newData.isActive,
        new Date(),
        this.userService.userProfile.name
      );
      this.dataService
        .add<any>(
          `OrderMinimum/AddOrderMinimum/${this.userService.userProfile.companyCode}/${newData.currencyCode}/${newData.countryCode}/${newData.orderMinimumAmount}/${newData.handlingCharge}/${newData.orderYear}/${newData.isActive}`,
          addOrderMinimum
        )
        .subscribe((response) => {
          this._cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Create Order Minimum',
              `Order minimum created successfully`
            )
          );
        });
    } else if (
      args.requestType === 'save' &&
      args.action === 'edit' &&
      args.data
    ) {
      const newData = args.data as {
        id: number;
        currencyCode: string;
        countryCode: string;
        orderMinimumAmount: number;
        handlingCharge: number;
        orderYear: number;
        isActive: boolean;
      };
      const updateOrderMinimum = new OrderMinimum(
        newData.id,
        new Date(),
        this.userService.userProfile.companyCode,
        newData.currencyCode,
        newData.countryCode,
        newData.orderMinimumAmount,
        newData.handlingCharge,
        newData.orderYear,
        newData.isActive,
        new Date(),
        this.userService.userProfile.name
      );
      this.dataService
        .update<any>(
          `OrderMinimum/UpdateOrderMinimum/${newData.id}/${this.userService.userProfile.companyCode}/${newData.currencyCode}/${newData.countryCode}/${newData.orderMinimumAmount}/${newData.handlingCharge}/${newData.orderYear}/${newData.isActive}`,
          updateOrderMinimum
        )
        .subscribe((response) => {
          this._cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Update Order Minimum',
              `Order Minimum updated successfully`
            )
          );
        });
    }
  }
  public clickHandler(args: ClickEventArgs): void {
    if (args.item.text === 'Clone') {
      this.getOrderYear();
      this.ejDialogCloneOrder.show();
    }
  }
  public onOrderMinimumGridActionBegin(args: any): void {
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'dateCreated'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'dateCreated') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'companyCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'companyCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'currencyCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'currencyCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'countryCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'countryCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'orderMinimumAmount'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'orderMinimumAmount') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'handlingCharge'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'handlingCharge') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'orderYear'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'orderYear') {
          col.operator = 'contains';
        }
      });
    }
  }

  public getOrderMinimum() {
    this.dataService
      .getAll<OrderMinimum>(
        `OrderMinimum/GetOrderMinimum/${
          this.userService.userProfile.companyCode
        }/${new Date().getFullYear()}`
      )
      .subscribe((data) => {
        this.orderMinimumList = data;
      });
  }

  public onClickAddButton() {
    this.addOrderForm.reset();
    this.ejDialogAddOrder.show();
  }

  // All Cancel Buttons here//
  public onAddOrderDialogCancelButtonClick() {
    this.addOrderForm.reset();
    this.ejDialogAddOrder.hide();
  }
  public onCloneOrderDialogCancelButtonClick() {
    this.ejDialogCloneOrder.hide();
  }
  public getOrderYear(): void {
    this.dataService
      .getAll<OrderMinimum>('OrderMinimum/GetOrderYearClone')
      .subscribe((data) => {
        this.orderYearList = data;
      });
  }
  public onSaveAddOrderDataButtonClick() {
    const dateCreatedValue = this.addOrderForm.get('dateCreated')?.value;
    if (dateCreatedValue && typeof dateCreatedValue === 'string') {
      this.dateCreatedValue = new Date(dateCreatedValue);
    }
    const orderMinimum: OrderMinimum = new OrderMinimum(
      parseInt(this.addOrderForm.get('id')?.value!),
      this.dateCreatedValue,
      this.addOrderForm.get('companyCode')?.value?.toString(),
      this.addOrderForm.get('currencyCode')?.value?.toString(),
      this.addOrderForm.get('countryCode')?.value?.toString(),
      parseInt(
        this.addOrderForm.get('orderMinimumAmount')?.value?.toString() ?? '0'
      ),
      parseInt(
        this.addOrderForm.get('handlingCharge')?.value?.toString() ?? '0'
      ),
      parseInt(this.addOrderForm.get('orderYear')?.value?.toString() ?? '0')
    );
    const serializedForm = JSON.stringify(orderMinimum);
    this.dataService
      .add<any>('OrderMinimum/AddOrderMinimum', serializedForm)
      .subscribe((data) => {
        const json = JSON.parse(data.message);
        const newOrder: OrderMinimum = new OrderMinimum(
          json.companyCode,
          json.currencyCode,
          json.countryCode,
          json.handlingCharge,
          json.orderYear
        );
        this.orderMinimumList.unshift(newOrder);
        this.orderMinimumGrid?.refresh();
        this.addOrderForm.reset();
        this.ejDialogAddOrder.hide();
      });
  }
  public onSaveCloneOrderDataButtonClick() {
    this.dataService
      .getAll<OrderMinimum>(
        `OrderMinimum/GetOrderMinimumClone/${
          this.userService.userProfile.companyCode
        }/${new Date().getFullYear()}`
      )
      .subscribe((data) => {
        this.orderMinimumList = data;
      });
    this.ejDialogCloneOrder.hide();
  }
  public onOrderMinimumGridRowSelected(selectedUser: any): void {
    this.orderMinimumList = selectedUser;
  }
  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(
    event: any,
    userSwitch: any,
    data: User
  ) {
    const checked: boolean = event.checked;
    this.user = data;

    this.userSwitch = userSwitch;
    if (checked) {
      this.dialogUserStatusWarning = 'Enable';
      this.isUserStatusDialogNoButtonClicked = false;
    } else {
      this.dialogUserStatusWarning = 'Disable';
      this.isUserStatusDialogNoButtonClicked = true;
    }
  }
}
