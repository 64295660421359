import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Router } from '@angular/router';
import { EmailDeliveryLog } from './model/email-delivery-log.model';
import { Browser } from '@syncfusion/ej2-base';
import {
  IAccPointRenderEventArgs,
  IAccLoadedEventArgs,
  AccumulationTheme,
} from '@syncfusion/ej2-angular-charts';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmailDeliveryLogDetails } from './model/email-delivery-log-details.model';

@Component({
  selector: 'app-email-delivery-log',
  templateUrl: './email-delivery-log.component.html',
  styleUrls: ['./email-delivery-log.component.scss'],
})
export class EmailDeliveryLogComponent {
  @ViewChild('dialogEmailDeliveryLogDetails')
  dialogEmailDeliveryLogDetails!: DialogComponent;
  @ViewChild('dialogEmailAddress')
  dialogEmailAddress!: DialogComponent;
  public emailDeliveryLogList: EmailDeliveryLog[] = [];
  public selectedRowData: EmailDeliveryLog[] = [];
  public emailDeliveryLogDetails: EmailDeliveryLogDetails[] = [];
  public emailAddressList: string[] | undefined = [];
  @ViewChild('emailDeliveryLogGrid')
  public emailDeliveryLogGrid?: GridComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;

  public userSwitch: any;

  // Declared  Variables
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public toolbarEmailDeliveryLogGrid?: ToolbarItems[];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  public pielegendSettings: Object = {
    visible: true,
  };
  public pieTooltipSetting: Object = { enable: true, format: '${point.x}' };
  public animation: Object = {
    enable: true,
  };
  public percentage: number = 0;
  public dataChart: Object[] = [];
  public border: Object = { width: 3 };
  public enableBorderOnMouseMove: boolean = false;
  public enableSmartLabels: boolean = false;
  public layoutColor: string = '';
  public columns: number = Browser.isDevice ? 2 : 8;
  public cellSpacing: number[] = [15, 15];
  public cellAspectRatio: number = Browser.isDevice ? 1 : 0.8;
  public pieSizeX: number = Browser.isDevice ? 1 : 3;
  public pieSizeY: number = Browser.isDevice ? 1 : 2;
  public pieColumn: number = Browser.isDevice ? 1 : 5;
  public columnSizeX: number = Browser.isDevice ? 1 : 5;
  public columnSizeY: number = Browser.isDevice ? 1 : 2;
  public palettes: string[] = [
    '#C152D2',
    '#CA765A',
    '#2485FA',
    '#8854D9',
    '#3D4EB8',
    '#00BCD7',
    '#4472c4',
    '#ed7d31',
    '#ffc000',
    '#70ad47',
    '#5b9bd5',
    '#c1c1c1',
    '#6f6fe2',
    '#e269ae',
    '#9e480e',
    '#997300',
  ];

  public dataLabel: Object = {
    visible: true,
    position: 'Outside',
    name: 'TextMapping',
    connectorStyle: { length: '10px', type: 'Curve' },
    font: {
      color: 'white',
    },
  };

  public startAngle: number = 270;
  public endAngle: number = 270;

  constructor(private dataService: DataserviceService) {}

  ngOnInit(): void {
    this.getEmailDeliveryLog();
    this.getEmailDeliveryLogPieChart();
    this.toolbarEmailDeliveryLogGrid = ['ColumnChooser', 'Search'];
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'View Details',
          id: 'viewDetails',
          iconCss: 'e-icons e-eye',
        },
        {
          text: 'Show Email',
          id: 'showEmail',
          iconCss: 'e-icons e-send-1',
        },
      ],
    },
  ];
  onEmailDeliveryContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'viewDetails':
          this.showDetails(this.selectedRowData);
          break;
        case 'showEmail':
          this.showEmailDetails(this.selectedRowData);
          break;
        default:
      }
    }
  }
  public pointRender(args: IAccPointRenderEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme || 'Material';
    if (selectedTheme.indexOf('highcontrast') > -1) {
      args.border.color = '#000000';
      this.layoutColor = '#000000';
    } else {
      args.border.color = '#FFFFFF';
      this.layoutColor = '#FFFFFF';
    }
  }
  public accumulationload(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme || 'Material';
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }

  public getEmailDeliveryLog() {
    this.dataService
      .getAll<EmailDeliveryLog>('EmailDeliveryLog/GetEmailDeliveryLog')
      .subscribe((data) => {
        this.emailDeliveryLogList = data;
      });
  }

  public getEmailDeliveryLogPieChart() {
    this.dataService
      .getAll<EmailDeliveryLog>('EmailDeliveryLog/GetEmailDeliveryLogPieChart')
      .subscribe((data) => {
        this.dataChart = [];

        data.forEach((log) => {
          const percentage =
            ((log.occurrenceCount ?? 0) / (log.totalOccurrenceCount ?? 0)) *
            100;

          this.dataChart.push({
            Product: log.description,
            Percentage: percentage,
            TextMapping: `${log.description}: ${percentage.toFixed(2)}%`,
          });
        });
      });
  }

  public onEmailDeliveryLogGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public onClickClearButton(): void {}

  public showDetails(data: any): void {
    let id = data.id;
    this.dataService
      .getAll<EmailDeliveryLogDetails>(
        `EmailDeliveryLog/GetEmailDeliveryLogDetails/${id}`
      )
      .subscribe((data) => {
        this.emailDeliveryLogDetails = data;
      });
    this.dialogEmailDeliveryLogDetails.show();
  }
  public showEmailDetails(data: any): void {
    let orderNumber = data.orderNumber;
    this.dataService
      .getAll<EmailDeliveryLog>(
        `EmailDeliveryLog/GetEmailAddress/${orderNumber}`
      )
      .subscribe((data) => {
        this.emailAddressList = data[0].emailAddress;
        console.log('dataaaaaa', this.emailAddressList);
      });
    this.dialogEmailAddress.show();
  }
  public hide(): void {
    this.dialogEmailDeliveryLogDetails.hide();
    this.dialogEmailAddress.hide();
  }
}
