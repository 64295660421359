import { Component, ViewChild } from '@angular/core';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { UserService } from 'src/app/services/user.service';
import { ReportColumnConfigurationDataType } from '../report-configuration/model/report-column-configuration-data-type.model';
import { ReportColumnConfigurationFormat } from '../report-configuration/model/report-column-configuration-format.model';
import { ReportColumnConfigurationTextAlign } from '../report-configuration/model/report-column-configuration-text-align.model';
import {
  ActionEventArgs,
  CommandModel,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  @ViewChild('dataTypeSettingsGrid')
  public dataTypeSettingsGrid?: GridComponent;
  @ViewChild('formatSettingsGrid')
  public formatSettingsGrid?: GridComponent;
  @ViewChild('textAlignSettingsGrid')
  public textAlignSettingsGrid?: GridComponent;
  public dataTypeSettingsList?: ReportColumnConfigurationDataType[];
  public typeData: string = '';
  public format: string = '';
  public textAlign: string = '';
  public isActive: boolean = false;
  public formatSettingsList: ReportColumnConfigurationFormat[] = [];
  public textAlignSettingsList: ReportColumnConfigurationTextAlign[] = [];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public editSettings?: EditSettingsModel;
  public toolbarOptions?: ToolbarItems[];

  public enableRowHover: boolean = false;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService
  ) {}

  ngOnInit(): void {
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
    };
    this.toolbarOptions = ['Add', 'Edit', 'Cancel', 'ColumnChooser', 'Search'];

    this.getReportConfigurationGetReportColumnConfigurationDataType();
    this.getReportConfigurationGetReportColumnConfigurationFormat();
    this.getReportConfigurationGetReportColumnConfigurationTextAlign();
  }
  public getReportConfigurationGetReportColumnConfigurationDataType() {
    this.dataService
      .getAll<ReportColumnConfigurationDataType>(
        `ReportConfiguration/GetReportColumnConfigurationDataType`
      )
      .subscribe((data) => {
        this.dataTypeSettingsList = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationFormat() {
    this.dataService
      .getAll<ReportColumnConfigurationFormat>(
        `ReportConfiguration/GetReportColumnConfigurationFormat`
      )
      .subscribe((data) => {
        this.formatSettingsList = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationTextAlign() {
    this.dataService
      .getAll<ReportColumnConfigurationTextAlign>(
        `ReportConfiguration/GetReportColumnConfigurationTextAlign`
      )
      .subscribe((data) => {
        this.textAlignSettingsList = data;
      });
  }
  public onActionCompleteDataType(args: ActionEventArgs): void {
    if (args.requestType === 'save' && args.action === 'add' && args.data) {
      const newDataType = args.data as { typeData: string };
      this.typeData = newDataType.typeData;
      if (this.typeData) {
        this.dataService
          .add<any>(
            `ReportConfiguration/AddReportColumnConfigurationDataType/${this.typeData}`,
            this.typeData
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Create Data Type',
                `Data Type created successfully`
              )
            );
          });
      }
    } else if (
      args.requestType === 'save' &&
      args.action === 'edit' &&
      args.data
    ) {
      const newDataType = args.data as {
        id: number;
        typeData: string;
        isActive: boolean;
      };
      this.typeData = newDataType.typeData;
      this.isActive = newDataType.isActive;
      let id = newDataType.id;
      if (this.typeData) {
        this.dataService
          .update<any>(
            `ReportConfiguration/UpdateReportColumnConfigurationDataType/${id}/${this.typeData}/${this.isActive}`,
            this.typeData
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Update Data Type',
                `Data Type Updated successfully`
              )
            );
          });
      }
    }
  }
  public onActionCompleteFormat(args: ActionEventArgs): void {
    if (args.requestType === 'save' && args.action === 'add' && args.data) {
      const newDataFormat = args.data as { format: string };
      this.format = newDataFormat.format;
      if (this.format) {
        this.dataService
          .add<any>(
            `ReportConfiguration/AddReportColumnConfigurationFormat/${this.format}`,
            this.format
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Create Format',
                `Format created successfully`
              )
            );
          });
      }
    } else if (
      args.requestType === 'save' &&
      args.action === 'edit' &&
      args.data
    ) {
      const newDataFormat = args.data as {
        id: number;
        format: string;
        isActive: boolean;
      };
      this.format = newDataFormat.format;
      this.isActive = newDataFormat.isActive;
      let id = newDataFormat.id;
      if (this.format) {
        this.dataService
          .update<any>(
            `ReportConfiguration/UpdateReportColumnConfigurationFormat/${id}/${this.format}/${this.isActive}`,
            this.format
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Update Format',
                `Format updated successfully`
              )
            );
          });
      }
    }
  }
  public onActionCompleteTextAlign(args: ActionEventArgs): void {
    if (args.requestType === 'save' && args.action === 'add' && args.data) {
      const newTextAlign = args.data as { textAlign: string };
      this.textAlign = newTextAlign.textAlign;
      if (this.textAlign) {
        this.dataService
          .add<any>(
            `ReportConfiguration/AddReportColumnConfigurationTextAlign/${this.textAlign}`,
            this.textAlign
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Create Text Alignment',
                `Text Align created successfully`
              )
            );
          });
      }
    } else if (
      args.requestType === 'save' &&
      args.action === 'edit' &&
      args.data
    ) {
      const newDataTextAlign = args.data as {
        id: number;
        textAlign: string;
        isActive: boolean;
      };
      this.textAlign = newDataTextAlign.textAlign;
      this.isActive = newDataTextAlign.isActive;
      let id = newDataTextAlign.id;
      if (this.textAlign) {
        this.dataService
          .update<any>(
            `ReportConfiguration/UpdateReportColumnConfigurationTextAlign/${id}/${this.textAlign}/${this.isActive}`,
            this.textAlign
          )
          .subscribe((response) => {
            this._cache.showDialog.next(
              new NotificationMessage(
                DialogType.info,
                'Update Text Align',
                `TextAlign updated successfully`
              )
            );
          });
      }
    }
  }

  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(event: any, userSwitch: any) {
    const checked: boolean = event.checked;
    if (checked) {
      return true;
    } else {
      return false;
    }
  }
}
